import React, { useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Label,
  FormGroup,
  ModalFooter,
  Button,
} from "reactstrap";
import { get_Report_type } from "../../../store/dashboard/dashboardActions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { submit_report } from "../../../store/dashboard/dashboardActions";
import { useState } from "react";
import DeleteModal from "../../../core/components/modal/DeleteModal";
import { useNavigate } from "react-router-dom";
import { GetTime } from "../../../core/components/Utility/Utility";
import TagsInput from "../../../pages/message/component/tagsInput/tagsInput";
import { AddMember, RemoveMember } from "../../../store/message/messageActions";
import deleteIcon from "../../../assets/img/chatDelete.svg";
import Autocomplete from "react-autocomplete";
import { GetAllUsers } from "../../../store/getAllUsers/allUsersAction";
import { callChat } from "../../../api/apiCallChat";
import SocketService from "../../../pages/message/service/socket/socket.service";
import MediaView from "../../../pages/message/component/MediaView/MediaView.jsx";
import sentIcon from "../../../assets/img/sentIcon.png";
export default function ForwardModel(props) {
  const chatService = SocketService;
  const { user } = useSelector((state) => state.auth);
  const { modal, toggle, data, setData, message } = props;
  const { conversations } = useSelector((state) => state.chat);
  const [optionalMessage, setOptionalMessage] = useState("");

  const [conversationList, setConversationList] = useState([]);
  const [textInput, setTextInput] = useState("");

  useEffect(() => {
    (async () => {
      try {
        const res = await callChat("get", `api/v1/rooms`, {
          searchTerm: textInput || undefined,
          limit: 100,
        });
        console.log(res?.data);

        if (res?.data?.status == "success") {
          setConversationList(res.data?.data?.rooms);
        }
      } catch (error) {
        setConversationList([]);
      }
    })();
  }, [textInput]);

  const [senderId, setSenderId] = useState([]);
  const handleSubmit = async (roomCode) => {
    const data = {
      message: optionalMessage,
      roomCode,
      originalMessage: message,
      messageType: 7,
      user: user?.data?.jid,
      isSystem: false,
    };
    chatService.send(data);
  };

  console.log(user);

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className="add-section report-modal fix-width gradient-background"
        centered
      >
        <ModalHeader style={{ padding: "20px !important" }} toggle={toggle}>
          Forward Message
        </ModalHeader>
        <ModalBody>
          <div className="mt-2">
            <div>
              {message?.messageType === 1 ? (
                <p className="p-2">{message.message}</p>
              ) : message?.messageType === 2 ? (
                <MediaView isImage={true} data={message?.mediaUrl} />
              ) : message?.messageType === 3 ? (
                <MediaView isVideo={true} data={message?.mediaUrl} />
              ) : message?.messageType === 4 ? (
                <MediaView isAudio={true} data={message?.mediaUrl} />
              ) : message?.messageType === 5 ? (
                <MediaView
                  isAttachment={true}
                  mediaName={message?.mediaName}
                  data={message?.mediaUrl}
                />
              ) : (
                <></>
              )}
            </div>
            <Input
              type="input"
              value={optionalMessage}
              id="exampleSelect"
              onChange={(e) => setOptionalMessage(e.target.value)}
              placeholder="Type a message(optional)"
            ></Input>
            <div className="d-flex mt-3 w-100 p-1">
              {/* <div className="autocomplete-wrapper1">
                <Autocomplete
                  inputProps={{ width: "90%" }}
                  open={tagsOpen}
                  value={textInput.val}
                  items={memberList}
                  getItemValue={(item) =>
                    `${item.first_name} ${item.last_name}`
                  }
                  renderMenu={(items, value) => (
                    <div className="dropdown">
                      {items.length === 0 && textInput !== ""
                        ? `No matches for ${value}`
                        : items}
                    </div>
                  )}
                  renderItem={(item, isHighlighted) => (
                    <div
                      className={`item ${isHighlighted ? "selected-item" : ""}`}
                    >
                      {item.first_name} {item.last_name}
                    </div>
                  )}
                  onChange={(event, val) => {
                    setTextInput({ val });
                    if (val.length === 0) {
                      if (tagsOpen) setTagsOpen(false);
                    } else {
                      if (!tagsOpen) setTagsOpen(true);
                    }
                  }}
                  onSelect={(val, obj) => {
                    if (!newMembers.includes(obj))
                      setNewMembers([...newMembers, obj]);
                    setTextInput("");
                    setTagsOpen(false);
                  }}
                  renderInput={(params) => {
                    return (
                      <input
                        {...params}
                        type="text"
                        onKeyDown={handleKeyDown}
                        className="tags-input"
                        placeholder={"Search for members"}
                      />
                    );
                  }}
                  wrapperStyle={{}}
                />
              </div> */}
              <Input
                type="input"
                value={textInput}
                id="exampleSelect"
                onChange={(e) => setTextInput(e.target.value)}
                placeholder="Search for members"
              ></Input>
            </div>

            <div className="mt-3">
              <h6>Suggested</h6>
              <div
                style={{ height: "200px", overflow: "auto", padding: "10px" }}
              >
                {conversationList?.map((item, index) => (
                  <div
                    key={index}
                    className="d-flex align-items-center justify-content-between mt-4 border-bottom"
                  >
                    <div className="d-flex align-items-center pb-2">
                      <img
                        src={item?.roomLogo}
                        alt=""
                        className="rounded-circle"
                        style={{ width: "40px", height: "40px" }}
                      />
                      <strong className="mb-0 ms-2">
                        {item?.roomName ||
                          chatService?.getRoomName(item?.users, user?.data?.id)}
                      </strong>
                    </div>
                    <div>
                      {!senderId.includes(item?.code) ? (
                        <Button
                          className="blue noBorder whiteText rounded"
                          onClick={() => {
                            handleSubmit(item?.code);
                            setSenderId([...senderId, item?.code]);
                          }}
                        >
                          Send
                        </Button>
                      ) : (
                        <Button className="blue noBorder whiteText rounded">
                          <img src={sentIcon} width={13} height={13} /> Sent
                        </Button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <ModalFooter className="d-flex justify-content-center mt-3">
              <button onClick={toggle} className={"blue noBorder whiteText"}>
                Done
              </button>
            </ModalFooter>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}
