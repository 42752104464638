import React, { useState, useEffect } from "react";
import "./MessagePeople.scss";
import ChatPeoples from "./ChatPeople/ChatPeople";
import { useDispatch, useSelector } from "react-redux";
import { initLoad, stopLoad } from "../../../../store/loader/loaderActions";
import { getConversation } from "../../../../store/message/messageActions";
import { useNavigate } from "react-router-dom";
import {
  REMOVE_GROUP_MEMBER,
  UPDATE_GROUP_MEMBER,
} from "../../../../store/types";

export default function MessagePeople({
  activeTab,
  setActiveTab,
  mediaType,
  setMediaType,
  filterType,
  setFilterType,
  activeBox,
  setActiveBox,
  // xmppService,
  chatService,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { conversations } = useSelector((state) => state.chat);
  const [searchTerm, setSearchTerm] = useState("");
  const [topDropdown, setTopDropdown] = useState(false);

  useEffect(() => {
    let payload = {
      type: "chat",
      searchTerm,
      searchType: mediaType || undefined,
    };

    if (filterType == "archive") Object.assign(payload, { filter: "archive" });
    else if (filterType == "request")
      Object.assign(payload, { filter: "request" });
    else if (filterType == "block") Object.assign(payload, { filter: "block" });
    else if (filterType == "spam") Object.assign(payload, { filter: "spam" });
    else if (filterType == "unread")
      Object.assign(payload, { filter: "unread" });

    if (activeTab == "group") Object.assign(payload, { type: "group" });
    dispatch(initLoad());
    dispatch(getConversation(payload))
      .then((res) => {
        const data = res?.data?.rooms;
        if (data?.length) {
          setActiveBox(data[0]);
          data?.forEach((room) => {
            chatService.join({
              user: `user_id-${user?.data?.id}`,
              room: room.code,
            });
          });
        }
        dispatch(stopLoad());
      })
      .catch((err) => dispatch(stopLoad()));

    const addMemberSubscription = chatService
      .onAddMember()
      .subscribe(({ updatedRoom }) => {
        dispatch({
          type: UPDATE_GROUP_MEMBER,
          payload: {
            updatedRoom,
          },
        });
      });

    const romoveMemberSubscription = chatService
      .onRemoveMember()
      .subscribe(({ roomCode, user_id }) => {
        dispatch({
          type: REMOVE_GROUP_MEMBER,
          payload: {
            roomCode,
            user_id,
          },
        });
      });

    return () => {
      addMemberSubscription.unsubscribe();
      romoveMemberSubscription.unsubscribe();
    };
  }, [searchTerm, activeTab, filterType, mediaType]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setMediaType(null);
  };

  const handleMediaTypeChange = (type) => {
    if (type == mediaType) setMediaType(null);
    else setMediaType(type);
    setSearchTerm("");
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setMediaType(null);
  };

  const renderTabs = () => {
    return (
      <div className="row align-items-center">
        <div className="col-4" onClick={() => handleTabChange("message")}>
          <div
            className={activeTab === "message" ? "msg-tab active" : "msg-tab"}
          >
            <h2>Message</h2>
          </div>
        </div>
        <div className="col-4" onClick={() => handleTabChange("group")}>
          <div
            className={
              activeTab === "group"
                ? "msg-tab text-end active"
                : "msg-tab text-end"
            }
          >
            <h2>Group</h2>
          </div>
        </div>
        <div className="col-4">
          <div className="float-end d-flex gap-3 align-items-center">
            <div className="dot">
              <div onClick={() => setTopDropdown(!topDropdown)}>
                <span>&bull;</span>
                <span>&bull;</span>
                <span>&bull;</span>
              </div>
              {topDropdown && (
                <div className="dropdown">
                  <ul>
                    <li onClick={() => navigate("/setting")}>
                      Manage Settings
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <div
              className="icon-edit"
              onClick={() => {
                handleTabChange("new");
                setActiveBox(null);
              }}
            >
              <div className="edit"></div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderFilters = () => {
    return (
      <div className="buttons">
        <div className="d-flex gap-2">
          <div
            className={mediaType == "photo" ? "btn btn-blue m-0" : "btn"}
            onClick={() => handleMediaTypeChange("photo")}
          >
            Photos
          </div>
          <div
            className={mediaType == "video" ? "btn btn-blue m-0" : "btn"}
            onClick={() => handleMediaTypeChange("video")}
          >
            Videos
          </div>
          <div
            className={mediaType == "link" ? "btn btn-blue m-0" : "btn"}
            onClick={() => handleMediaTypeChange("link")}
          >
            Links
          </div>
          <div
            className={mediaType == "doc" ? "btn btn-blue m-0" : "btn"}
            onClick={() => handleMediaTypeChange("doc")}
          >
            Docs
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="msg-sidebox mt-26">
      <div className="p-20 pb-0 msg-sidebox-top">
        {renderTabs()}
        <div className="search-box my-2">
          <input
            type="text"
            placeholder="Search message"
            className="form-control"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
        {renderFilters()}
      </div>

      <ChatPeoples
        activeTab={activeTab}
        activeBox={activeBox}
        setActiveBox={setActiveBox}
        filterType={filterType}
        setFilterType={setFilterType}
        data={conversations || []}
        handleTabChange={handleTabChange}
        // xmppService={xmppService}
        chatService={chatService}
      />
    </div>
  );
}
