import { React, useRef, useState, useEffect } from "react";
import "./navbar.scss";
import bell from "../../assets/img/bell.svg";
import noti from "../../assets/img/notification.svg";
import set from "../../assets/img/setting.svg";
import setWhite from "../../assets/img/setWhite.svg";
//import logo from "../../assets/img/logo.svg";
import logo from "../../assets/img/noblepageLogo.svg";
//import logo from "../../assets/img/pngnoblepage.png";
import wplogo from "../../assets/img/noble_profile_logo.png";
import walletActive from "../../assets/img/noble-job.jpg";
import placeholderUser from "../../assets/img/default_profile_sqr img.svg";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/auth/authActions";
import { GetAllUsers } from "../../store/getAllUsers/allUsersAction";
import Autocomplete from "react-autocomplete";
import { useLocation } from "react-router-dom";
// import WpToggleModal from "../../routes/workerPool/WpToggleModal";
import navIcon1 from "../../assets/img/navIcon1.png";
import navIcon2 from "../../assets/img/navIcon2.png";
import user_profile from "../../assets/img/viewprofile.svg";
import user_logout from "../../assets/img/Logout.svg";
import navIcon3 from "../../assets/img/navIcon3.png";
import { Input } from "reactstrap";
import { getAllNotification } from "../../store/notification/notificationAction.js";
import { profileApi } from "../../api/profileAPI.js";
import searchIcon from "../../assets/img/gg_search.svg";
import clockIcon from "../../assets/img/clock.svg";
import socketService from "../message/service/socket/socket.service.js";
var categories = [
  {
    name: "Home",
    route: "/dashboard",
    routeMap: ["/dashboard"],
    icon: "home-inactive",
    icon_active: "home",
  },
  {
    name: "My Network",
    route: "/network",
    routeMap: [
      "/network",
      "/posts",
      "/peoples",
      "/groups",
      "/event",
      "/pages",
      "/newsletter",
      "/articles",
      "/hashtag",
    ],
    icon: "union",
    icon_active: "union-active",
  },
  {
    name: "Jobs",
    route: "/jobs",
    routeMap: ["/jobs"],
    icon: "brief",
    icon_active: "brief-active",
  },
  {
    name: "Wallet",
    route: "/wallet",
    routeMap: ["/wallet"],
    icon: "wallet",
    icon_active: "wallet-active",
  },
  // {
  //   name: "Worker Pool",
  //   route: "/worker/login",
  //   routeMap: ["worker/login"],
  //   icon: "worker",
  //   icon_active: "worker-active",
  // }
];

export default function Navbar(props) {
  const { isJobs, setSearchKey, searchValue } = props;
  const { user } = useSelector((state) => state.auth);
  const { unreadNotifications, totalNotification } = useSelector(
    (state) => state.notification
  );
  const [admin, setAdmin] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const node1 = useRef();
  const [memberInput, setMemberInput] = useState(searchValue);
  const [memberList, setMemberList] = useState([]);
  const [sectionActive, setSectionActive] = useState("");
  const [notificationCount, setNotificationCount] = useState(0);
  const { pathname } = useLocation();
  const [recentSearch, setRecentSearch] = useState({
    recent_search: [],
    trySearch: [],
  });
  const globalSearch = async (search) => {
    const res = await profileApi.getGlobalSearch(search);
    if (res?.success) {
      setMemberList(res?.data);
    }
  };

  const recentSearchApi = async () => {
    const res = await profileApi.getRecentSearch();
    if (res.success) {
      setRecentSearch(res?.data);
    }
  };

  useEffect(() => {
    if (memberInput) {
      globalSearch(memberInput);
    } else {
      recentSearchApi();
    }
  }, [memberInput]);

  useEffect(() => {
    if (user?.data) recentSearchApi();
  }, []);

  const adminClick = () => {
    setAdmin(!admin);
  };

  const clickOutsideAdmin = (e) => {
    if (node1.current.contains(e.target)) {
      return;
    }
    setAdmin(true);
  };

  useEffect(() => {
    document.addEventListener("mousedown", clickOutsideAdmin);

    return () => {
      document.removeEventListener("mousedown", clickOutsideAdmin);
    };
  }, [admin]);

  useEffect(() => {
    for (var i = 0; i < categories.length; i++) {
      if (categories[i].routeMap.includes(pathname)) {
        setSectionActive(categories[i].name);
        break;
      }
    }
  }, [pathname]);

  const handleLogout = async () => {
    try {
      socketService.disconnect();

      dispatch(logout())
        .then(() => {
          navigate("/");
        })
        .catch(() => {
          navigate("/");
        });
    } catch (e) {
      console.log(e);
    }
  };
  const goToSttings = () => {
    navigate("/setting");
  };
  // const updateWP = (e) => {
  //   setIsWPActivated(e.target.checked);
  //   if (e.target.checked) {
  //     setWPToggleModal(true);
  //   }
  // };

  const handleEnterKey = (e) => {
    if (e.key == "Enter") return true;
    return false;
  };

  const [open, setOpen] = useState(false);

  const flattenedResults = Object.entries(memberList).flatMap(
    ([category, items]) => {
      return items.map((item) => ({
        ...item,
        category, // Adding category information to each item
      }));
    }
  );

  const getItemValue = (item) => {
    return item.first_name
      ? `${item.first_name} ${item.last_name}`
      : item.name || item.title; // Default values for display
  };

  const deleteRecentSearch = async () => {
    const res = await profileApi.deleteRecentSearch({ platform_type: "np" });
    if (res?.success) {
      setRecentSearch({
        ...recentSearch,
        recent_search: [],
      });
    }
  };

  const createRecentSearch = async (data) => {
    try {
      await profileApi.createRecentSearch(data);
    } catch (err) {
      console.log(err);
    }
  };

  const renderMenu = (items, value, style) => {
    return (
      <div className="dropdown" style={{ width: "500px", zIndex: 99 }}>
        {items.length === 0 || !memberInput ? (
          <>
            <div className="d-flex flex-column p-3">
              <div className="d-flex justify-content-between align-items-center">
                <p className="fw-bold text-secondary">Recent</p>
                {recentSearch?.recent_search?.length > 0 && (
                  <p
                    className="fw-bold text-secondary"
                    onClick={deleteRecentSearch}
                  >
                    Clear
                  </p>
                )}
              </div>
              {recentSearch?.recent_search?.length > 0 ? (
                <div className="mt-2">
                  {recentSearch?.recent_search?.map((ele) => {
                    return (
                      <>
                        {ele?.type === "user" && (
                          <div
                            className="d-flex flex-wrap align-items-center p-1"
                            onClick={() => {
                              navigate(`/profile/${ele.type_id}`, {
                                state: { search: true },
                              });
                              setOpen(false);
                              createRecentSearch({
                                platform_type: "np",
                                search: ele.search,
                                type: "user",
                                type_id: ele.type_id,
                                image: ele.image,
                              });
                            }}
                          >
                            <img
                              src={ele.image || placeholderUser}
                              alt="profile"
                              style={{
                                height: "36px",
                                width: "36px",
                                marginRight: "10px",
                                borderRadius: "50%",
                              }}
                            />
                            <span className="fw-bold">
                              {ele?.search?.length > 10
                                ? ele?.search.slice(0, 10) + "..."
                                : ele?.search}
                            </span>
                          </div>
                        )}
                        {ele?.type === "page" && (
                          <div
                            className="d-flex align-items-center mx-2 p-1"
                            onClick={() => {
                              navigate(
                                `/directory/companydetail/${ele.type_id}`
                              );
                              setOpen(false);
                              createRecentSearch({
                                platform_type: "np",
                                search: ele.search,
                                type: "page",
                                type_id: ele.type_id,
                              });
                            }}
                          >
                            <img src={clockIcon} width={20} height={20} />
                            <p className="fw-bold mx-2">
                              {ele?.search?.length > 10
                                ? ele?.search.slice(0, 10) + "..."
                                : ele?.search}
                            </p>
                            <p className=" mt-0">in page</p>
                          </div>
                        )}

                        {ele?.type === "job" && (
                          <>
                            <div
                              className="d-flex align-items-center mx-2 p-1"
                              onClick={() => {
                                navigate(`/directory/jobdetail/${ele.type_id}`);
                                setOpen(false);
                                createRecentSearch({
                                  platform_type: "np",
                                  search: ele.search,
                                  type: "job",
                                  type_id: ele.type_id,
                                });
                              }}
                            >
                              <img src={clockIcon} width={20} height={20} />
                              <p className="fw-bold mx-2">
                                {ele?.search?.length > 10
                                  ? ele?.search.slice(0, 10) + "..."
                                  : ele?.search}
                              </p>
                              <p className=" mt-0">in job</p>
                            </div>
                          </>
                        )}
                        {ele?.type === "group" && (
                          <>
                            <div
                              className="d-flex align-items-center mx-2 p-1"
                              onClick={() => {
                                navigate(`/groupdetails/${ele.type_id}`);
                                setOpen(false);

                                createRecentSearch({
                                  platform_type: "np",
                                  search: ele.search,
                                  type: "group",
                                  type_id: ele.type_id,
                                });
                              }}
                            >
                              <img src={clockIcon} width={20} height={20} />
                              <p className="fw-bold mx-2">
                                {ele?.search?.length > 10
                                  ? ele?.search.slice(0, 10) + "..."
                                  : ele?.search}
                              </p>
                              <p className=" mt-0">in group</p>
                            </div>
                          </>
                        )}
                        {ele?.type === "event" && (
                          <>
                            <div
                              className="d-flex align-items-center mx-2 p-1"
                              onClick={() => {
                                navigate(`/eventdetail/${ele.type_id}`);
                                setOpen(false);

                                createRecentSearch({
                                  platform_type: "np",
                                  search: ele.search,
                                  type: "event",
                                  type_id: ele.type_id,
                                });
                              }}
                            >
                              <img src={clockIcon} width={20} height={20} />
                              <p className="fw-bold mx-2">
                                {ele?.search?.length > 10
                                  ? ele?.search.slice(0, 10) + "..."
                                  : ele?.search}
                              </p>
                              <p className=" mt-0">in event</p>
                            </div>
                          </>
                        )}
                        {ele?.type === "article" && (
                          <>
                            <div
                              className="d-flex align-items-center mx-2 p-1"
                              onClick={() => {
                                navigate(`/articledetail/${ele.type_id}`);
                                setOpen(false);

                                createRecentSearch({
                                  platform_type: "np",
                                  search: ele.search,
                                  type: "article",
                                  type_id: ele.type_id,
                                });
                              }}
                            >
                              <img src={clockIcon} width={20} height={20} />
                              <p className="fw-bold mx-2">
                                {ele?.search?.length > 10
                                  ? ele?.search.slice(0, 10) + "..."
                                  : ele?.search}
                              </p>
                              <p className=" mt-0">in article</p>
                            </div>
                          </>
                        )}
                        {ele?.type === "newsletter" && (
                          <>
                            <div
                              className="d-flex align-items-center mx-2 p-1"
                              onClick={() => {
                                navigate(`/newsletterdetail/${ele.type_id}`);
                                setOpen(false);

                                createRecentSearch({
                                  platform_type: "np",
                                  search: ele.search,
                                  type: "newsletter",
                                  type_id: ele.type_id,
                                });
                              }}
                            >
                              <img src={clockIcon} width={20} height={20} />
                              <p className="fw-bold mx-2">
                                {ele?.search?.length > 10
                                  ? ele?.search.slice(0, 10) + "..."
                                  : ele?.search}
                              </p>
                              <p className=" mt-0">in newsletter</p>
                            </div>
                          </>
                        )}
                      </>
                    );
                  })}
                </div>
              ) : (
                <>No recent search</>
              )}
            </div>

            <div className="d-flex flex-column p-3">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <p className="text-secondary">Try searching for</p>
              </div>
              {recentSearch?.trySearch?.map((ele) => {
                return (
                  <>
                    {ele?.type === "user" && (
                      <div
                        className="d-flex flex-wrap align-items-center p-1"
                        onClick={() => {
                          navigate(`/profile/${ele.type_id}`, {
                            state: { search: true },
                          });
                          setOpen(false);

                          createRecentSearch({
                            platform_type: "np",
                            search: ele.search,
                            type: "user",
                            type_id: ele.type_id,
                            image: ele.image,
                          });
                        }}
                      >
                        <img
                          src={ele.image || placeholderUser}
                          alt="profile"
                          style={{
                            height: "36px",
                            width: "36px",
                            marginRight: "10px",
                            borderRadius: "50%",
                          }}
                        />
                        <span className="fw-bold">
                          {ele?.search?.length > 10
                            ? ele?.search.slice(0, 10) + "..."
                            : ele?.search}
                        </span>
                      </div>
                    )}
                    {ele?.type === "page" && (
                      <div
                        className="d-flex align-items-center p-1"
                        onClick={() => {
                          navigate(`/directory/companydetail/${ele.type_id}`);
                          setOpen(false);

                          createRecentSearch({
                            platform_type: "np",
                            search: ele.search,
                            type: "page",
                            type_id: ele.type_id,
                          });
                        }}
                      >
                        <img src={searchIcon} width={20} height={20} />
                        <p className="fw-bold mx-2">
                          {ele?.search?.length > 10
                            ? ele?.search.slice(0, 10) + "..."
                            : ele?.search}
                        </p>
                        <p className=" mt-0">in page</p>
                      </div>
                    )}

                    {ele?.type === "job" && (
                      <>
                        <div
                          className="d-flex align-items-center  p-1"
                          onClick={() => {
                            navigate(`/directory/jobdetail/${ele.type_id}`);
                            setOpen(false);

                            createRecentSearch({
                              platform_type: "np",
                              search: ele.search,
                              type: "job",
                              type_id: ele.type_id,
                            });
                          }}
                        >
                          <img src={searchIcon} width={20} height={20} />
                          <p className="fw-bold mx-2">
                            {ele?.search?.length > 10
                              ? ele?.search.slice(0, 10) + "..."
                              : ele?.search}
                          </p>
                          <p className=" mt-0">in job</p>
                        </div>
                      </>
                    )}
                    {ele?.type === "group" && (
                      <>
                        <div
                          className="d-flex align-items-center  p-1"
                          onClick={() => {
                            navigate(`/groupdetails/${ele.type_id}`);
                            setOpen(false);

                            createRecentSearch({
                              platform_type: "np",
                              search: ele.search,
                              type: "group",
                              type_id: ele.type_id,
                            });
                          }}
                        >
                          <img src={searchIcon} width={20} height={20} />
                          <p className="fw-bold mx-2">
                            {ele?.search?.length > 10
                              ? ele?.search.slice(0, 10) + "..."
                              : ele?.search}
                          </p>
                          <p className=" mt-0">in group</p>
                        </div>
                      </>
                    )}
                    {ele?.type === "event" && (
                      <>
                        <div
                          className="d-flex align-items-center  p-1"
                          onClick={() => {
                            navigate(`/eventdetail/${ele.type_id}`);
                            setOpen(false);

                            createRecentSearch({
                              platform_type: "np",
                              search: ele.search,
                              type: "event",
                              type_id: ele.type_id,
                            });
                          }}
                        >
                          <img src={searchIcon} width={20} height={20} />
                          <p className="fw-bold mx-2">
                            {ele?.search?.length > 10
                              ? ele?.search.slice(0, 10) + "..."
                              : ele?.search}
                          </p>
                          <p className=" mt-0">in event</p>
                        </div>
                      </>
                    )}
                    {ele?.type === "article" && (
                      <>
                        <div
                          className="d-flex align-items-center  p-1"
                          onClick={() => {
                            navigate(`/articledetail/${ele.type_id}`);
                            setOpen(false);

                            createRecentSearch({
                              platform_type: "np",
                              search: ele.search,
                              type: "article",
                              type_id: ele.type_id,
                            });
                          }}
                        >
                          <img src={searchIcon} width={20} height={20} />
                          <p className="fw-bold mx-2">
                            {ele?.search?.length > 10
                              ? ele?.search.slice(0, 10) + "..."
                              : ele?.search}
                          </p>
                          <p className=" mt-0">in article</p>
                        </div>
                      </>
                    )}
                    {ele?.type === "newsletter" && (
                      <>
                        <div
                          className="d-flex align-items-center  p-1"
                          onClick={() => {
                            navigate(`/newsletterdetail/${ele.type_id}`);
                            setOpen(false);

                            createRecentSearch({
                              platform_type: "np",
                              search: ele.search,
                              type: "newsletter",
                              type_id: ele.type_id,
                            });
                          }}
                        >
                          <img src={searchIcon} width={20} height={20} />
                          <p className="fw-bold mx-2">
                            {ele?.search?.length > 10
                              ? ele?.search.slice(0, 10) + "..."
                              : ele?.search}
                          </p>
                          <p className=" mt-0">in newsletter</p>
                        </div>
                      </>
                    )}

                    {ele?.type === "" && (
                      <div
                        className="d-flex align-items-center p-1"
                        onClick={() => {
                          navigate("/search", {
                            state: { search: ele?.search, type: "" },
                          });
                          setOpen(false);

                          createRecentSearch({
                            platform_type: "np",
                            search: ele.search,
                            type: null,
                            type_id: null,
                          });
                        }}
                      >
                        <img src={searchIcon} width={20} height={20} />
                        <p className="fw-bold mx-2">
                          {ele?.search?.length > 10
                            ? ele?.search.slice(0, 10) + "..."
                            : ele?.search}
                        </p>
                        <p className=" mt-0">in page</p>
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          </>
        ) : (
          <>
            {items}
            <p
              className="text-center mt-3"
              onClick={() =>
                navigate("/search", {
                  state: { search: memberInput, type: "" },
                })
              }
            >
              See All Results
            </p>
          </>
        )}
      </div>
    );
  };

  const renderItem = (item, isHighlighted) => {
    return (
      <div
        className={`item ${isHighlighted ? "selected-item" : ""}`}
        style={{
          display: "flex",
          padding: "5px 10px",
          cursor: "pointer",
          backgroundColor: isHighlighted ? "#f0f0f0" : "transparent",
        }}
      >
        <img src={searchIcon} width={20} className="me-3" />

        {item.category === "people" && (
          <div className="d-flex flex-wrap">
            <img
              src={item.profile_img || placeholderUser}
              alt="profile"
              style={{
                height: "36px",
                width: "36px",
                marginRight: "10px",
                borderRadius: "50%",
              }}
            />
            <span className="fw-bold">
              {item?.first_name?.length > 10
                ? item.first_name.slice(0, 10) + "..."
                : item.first_name}{" "}
              {item?.last_name?.length > 10
                ? item.last_name.slice(0, 10) + "..."
                : item.last_name}
            </span>
          </div>
        )}

        {item.category === "company" && (
          <div className="d-flex">
            <p className="fw-bold">
              {item.name?.length > 10
                ? item.name.slice(0, 10) + "..."
                : item.name}
            </p>
            <ul
              style={{
                listStyleType: "circle",
                display: "flex",
              }}
            >
              <li className="mx-3">company</li>
              <li className="mx-3">
                {item.industry?.length > 10
                  ? item.industry.slice(0, 10) + "..."
                  : item.industry}
              </li>
            </ul>
          </div>
        )}

        {item.category === "job" && (
          <>
            <span className="fw-bold">
              {item.title?.length > 10
                ? item.title.slice(0, 10) + "..."
                : item.title}{" "}
            </span>
            <span className="mx-2"> in job</span>
          </>
        )}
        {item.category === "group" && (
          <>
            <span className="fw-bold">
              {item.name?.length > 10
                ? item.name.slice(0, 10) + "..."
                : item.name}{" "}
            </span>
            <span className="mx-2"> in group</span>
          </>
        )}
        {item.category === "event" && (
          <>
            <span className="fw-bold">
              {item.title?.length > 10
                ? item.title.slice(0, 10) + "..."
                : item.title}{" "}
            </span>
            <span className="mx-2"> in event</span>
          </>
        )}
        {item.category === "article" && (
          <>
            <span className="fw-bold">
              {item.title?.length > 10
                ? item.title.slice(0, 10) + "..."
                : item.title}{" "}
            </span>
            <span className="mx-2"> in article</span>
          </>
        )}
        {item.category === "newsletter" && (
          <>
            <span className="fw-bold">
              {item.title?.length > 10
                ? item.title.slice(0, 10) + "..."
                : item.title}{" "}
            </span>
            <span className="mx-2">in newsletter</span>
          </>
        )}

        {item.category === "recent_search" && (
          <>
            <span className="fw-bold">
              {item.search?.length > 10
                ? item.search.slice(0, 10) + "..."
                : item.search}{" "}
            </span>
          </>
        )}
      </div>
    );
  };

  const handleChange = (event, value) => {
    setMemberInput(value);
    if (value) setOpen(true);
  };

  const handleSelect = (value, item) => {
    setMemberInput(value);
    setOpen(false);

    if (item.category === "people") {
      createRecentSearch({
        platform_type: "np",
        search: item.first_name,
        type: "user",
        type_id: item.id,
        image: item.profile_img,
      });
      navigate(`/profile/${item.id}`, {
        state: { search: true },
      });
    } else if (item.category === "company") {
      createRecentSearch({
        platform_type: "np",
        search: item.name,
        type: "page",
        type_id: item.id,
        image: null,
      });
      navigate(`/directory/companydetail/${item.id}`);
    } else if (item.category === "job") {
      createRecentSearch({
        platform_type: "np",
        search: item.title,
        type: "job",
        type_id: item.id,
        image: null,
      });
      navigate(`/directory/jobdetail/${item.id}`);
    } else if (item.category === "group") {
      createRecentSearch({
        platform_type: "np",
        search: item.name,
        type: "group",
        type_id: item.id,
        image: null,
      });
      navigate(`/groupdetails/${item.id}`);
    } else if (item.category === "article") {
      createRecentSearch({
        platform_type: "np",
        search: item.title,
        type: "article",
        type_id: item.id,
        image: null,
      });
      navigate(`/articledetail/${item.id}`);
    } else if (item.category === "newsletter") {
      createRecentSearch({
        platform_type: "np",
        search: item.title,
        type: "newsletter",
        type_id: item.id,
        image: null,
      });
      navigate(`/newsletterdetail/${item.id}`);
    } else if (item.category === "event") {
      createRecentSearch({
        platform_type: "np",
        search: item.title,
        type: "event",
        type_id: item.id,
        image: null,
      });
      navigate(`/eventdetail/${item.id}`);
    } else if (item.category === "recent_search") {
      navigate("/search", { state: { search: item.search, type: "" } });
    }
  };

  return (
    <div>
      <header className="dashboard">
        <nav className="navbar navbar-expand-xl bg-white navbar-white p-0">
          <div
            className="container"
            style={{ paddingLeft: "7px", paddingRight: "4px" }}
          >
            <div className="logo-search-box">
              <div
                className="navbar-brand"
                onClick={(e) => {
                  e.preventDefault();
                  // navigate("/dashboard");
                }}
              >
                <Link to={"/dashboard"}>
                  <img src={logo} alt="noblepage_logo" />
                </Link>
              </div>
              <div className="autocomplete-wrapper">
                <div className="search">
                  {isJobs ? (
                    <Input
                      type="text"
                      onChange={(e) => {
                        const { value } = e.target;
                        // setSearchKey(value);
                        if (value.trim() === "") {
                          setSearchKey("");
                        }
                      }}
                      onKeyDown={(e) =>
                        handleEnterKey(e) && setSearchKey(e.target.value)
                      }
                      placeholder="Search Jobs"
                    />
                  ) : (
                    <Autocomplete
                      open={open}
                      inputProps={{
                        placeholder: "Search",
                        onFocus: () => setOpen(true),
                        onBlur: () => setOpen(false), // Optional: close when out of focus
                      }}
                      value={memberInput}
                      items={flattenedResults}
                      getItemValue={getItemValue}
                      renderMenu={renderMenu}
                      renderItem={renderItem}
                      onChange={handleChange}
                      onSelect={handleSelect}
                      wrapperStyle={{
                        position: "relative", // Ensures proper display of dropdown
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapsibleNavbar"
              aria-expanded="false"
            >
              {/* <span className="navbar-toggler-icon"></span> */}
              <span></span>
              <span></span>
              <span></span>
            </button>
            {/* collapse navbar-collapse" id="collapsibleNavbar */}
            <div className="nav-for-desktop" id="">
              <ul className="navbar-nav ms-auto">
                {categories.map((menu, idx) => {
                  return (
                    <li
                      className="nav-item"
                      key={idx}
                      // onClick={() => {
                      //   menu.route === "/jobs"
                      //     ? alert(
                      //         "This feature will be implemented in the future milestone"
                      //       )
                      //     : navigate(menu.route);
                      // }}
                      // onClick={() => {
                      //   navigate(menu.route);
                      // }}
                    >
                      <Link
                        to={menu.route}
                        className={
                          menu.name === sectionActive
                            ? "nav-link colornav"
                            : "nav-link"
                        }
                      >
                        <i
                          className={
                            menu.name === sectionActive
                              ? menu.icon_active
                              : menu.icon
                          }
                        ></i>
                        {menu.name}
                      </Link>
                    </li>
                  );
                })}
                <ul
                  style={{ display: "flex", gap: "2px", alignItems: "center" }}
                >
                  {/* <li className="nav-item">
                  <div className="d-flex">
                  <div className="switch">
                              <label className="switch">
                   
                   
                              <input
                                  type="checkbox"
                                   onClick={(e) => updateWP(e)}
                                  checked={isWPActivated}
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                            </div>
                  </li> */}
                  <li className="nav-item">
                    <div className="nav-link notii">
                      <div
                        className="msg background_grey"
                        // onClick={() => {
                        //   // alert(
                        //   //   "This feature will be implemented in the future milestone"
                        //   // );
                        //   navigate("/message");
                        // }}
                      >
                        <Link to="/message">
                          <img src={noti} alt="notification" />
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li className="nav-item">
                    <Link to="/notifications">
                      <div className="nav-link notii">
                        <div className="msg background_grey">
                          <img src={bell} alt="bell" />
                          {totalNotification > 0 && (
                            <span
                              style={{
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {" "}
                              {totalNotification}
                            </span>
                          )}
                        </div>
                      </div>
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                  <a className="nav-link" href="/">
                    <div className="btn" onClick={handleLogout}>
                      Logout
                    </div>
                  </a>
                </li> */}
                  <li className="nav-item">
                    <a className="nav-link">
                      <div
                        className={
                          pathname === "/setting"
                            ? `msg active background_blue`
                            : `msg active background_grey`
                        }
                      >
                        <Link to="/setting">
                          <img
                            src={pathname === "/setting" ? setWhite : set}
                            // onClick={goToSttings}
                            alt="setting"
                          />
                        </Link>
                      </div>
                    </a>
                  </li>
                  <li className="nav-item" ref={node1}>
                    <a className="nav-link">
                      <div className="prof">
                        <img
                          src={
                            user?.data?.profile_img
                              ? user?.data?.profile_img
                              : placeholderUser
                          }
                          alt=""
                          onClick={() => adminClick()}
                        />
                        {!admin ? (
                          <div className="admin">
                            <ul>
                              <li className="dropdown-span">
                                <img
                                  src={user_profile}
                                  alt=""
                                  style={{ height: "17px", width: "17px" }}
                                />
                                <span
                                  onClick={() =>
                                    navigate(`/profile/${user?.data?.id}`)
                                  }
                                >
                                  View Profile
                                </span>
                              </li>
                              {/* <li className="dropdown-span">
                                <span>Language</span>
                              </li> */}
                              <li className="dropdown-span">
                                <img
                                  src={user_logout}
                                  alt="log_out"
                                  style={{ height: "17px", width: "17px" }}
                                />
                                <span onClick={handleLogout}>Log out</span>
                              </li>
                            </ul>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </a>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <div
        className="nav-for-mobile collapse navbar-collapse"
        id="collapsibleNavbar"
      >
        <ul>
          <li>
            <Link to="/">
              {/* <img src={navIcon1} alt="Icon" />  */}
              <i className={"home-inactive"}></i>
              Home
            </Link>
          </li>
          <li>
            <Link to="/network">
              {/* <img src={navIcon2} alt="Icon" />  */}
              <i className={"union"}></i>
              My Network
            </Link>
          </li>
          <li>
            <Link to="/jobs">
              <i className={"brief"}></i>
              Jobs
            </Link>
          </li>
          <li>
            <Link to="/wallet">
              <i className={"wallet"}></i> Wallet
            </Link>
          </li>
          <li>
            <Link to="/message">
              <img src={noti} alt="message" />
              Messages
            </Link>
          </li>
          <li>
            <Link to="/notifications">
              {" "}
              <img src={bell} alt="bell" />
              Notifications
            </Link>
          </li>
          <li>
            <Link to="/setting">
              {" "}
              <img src={set} alt="setting" />
              Settings
            </Link>
          </li>
          <li>
            <Link to={`/profile/${user?.data?.id}`}>
              {" "}
              <img
                src={user_profile}
                alt=""
                style={{ height: "17px", width: "17px" }}
              />
              View Profile
            </Link>
          </li>
          {/* <li>
            <Link to="/">Language</Link>
          </li> */}
          <li>
            <Link onClick={handleLogout}>
              <img
                src={user_logout}
                alt="log_out"
                style={{ height: "17px", width: "17px" }}
              />
              Log Out
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

Navbar.defaultProps = {
  searchValue: "",
};
