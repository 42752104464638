export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const LOGOUT_PORTAL = "LOGOUT_PORTAL";
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const EXPLORE_SUCCESS = "EXPLORE_SUCCESS";
export const EXPLORE_FAIL = "EXPLORE_FAIL";
export const EXPLORE_DETAILS_SUCCESS = "EXPLORE_DETAILS_SUCCESS";
export const EXPLORE_DETAILS_FAIL = "EXPLORE_DETAILS_FAIL";
export const INIT_SUCCESS = "INIT_SUCCESS";
export const INIT_FAIL = "INIT_FAIL";
export const GET_DATA = "GET_DATA";
export const UPDATE_DATA = "UPDATE_DATA";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const ADD_EDUCATION_DATA = "ADD_EDUCATION_DATA";
export const UPDATE_EDUCATION_DATA = "UPDATE_EDUCATION_DATA";
export const ADD_COURSE_DATA = "ADD_COURSE_DATA";
export const UPDATE_COURSE_DATA = "UPDATE_COURSE_DATA";
export const ADD_LANGUAGE_DATA = "ADD_LANGUAGE_DATA";
export const UPDATE_LANGUAGE_DATA = "UPDATE_LANGUAGE_DATA";
export const ADD_LICENSE_DATA = "ADD_LICENSE_DATA";
export const UPDATE_LICENSE_DATA = "UPDATE_LICENSE_DATA";
export const ADD_SKILLS_DATA = "ADD_SKILLS_DATA";
export const UPDATE_SKILLS_DATA = "UPDATE_SKILLS_DATA";
export const UPDATE_CONNECTION_STATUS = "UPDATE_CONNECTION_STATUS";
export const DELETE_DATA = "DELET_DATA";
export const REACTION_POST = "REACTION_POST";
export const REACTION_POST_FAIL = "REACTION_POST_FAIL";
export const GET_REACTION_POST = "GET_REACTION_POST";
export const GET_REACTION_POST_FAIL = "GET_REACTION_POST_FAIL";
export const GET_SUGGESTED_NEWSLETTERS = "GET_SUGGESTED_NEWSLETTERS";
export const LOAD_MORE_GET_SUGGESTED_NEWSLETTERS =
  "LOAD_MORE_GET_SUGGESTED_NEWSLETTERS";
export const GET_MY_NEWSLETTERS = "GET_MY_NEWSLETTERS";
export const LOAD_MORE_GET_MY_NEWSLETTERS = "LOAD_MORE_GET_MY_NEWSLETTERS";
export const GET_PAGE_NEWSLETTERS = "GET_PAGE_NEWSLETTERS";
export const GET_SUBSCRIBED_NEWSLETTERS = "GET_SUBSCRIBED_NEWSLETTERS";
export const LOAD_MORE_GET_SUBSCRIBED_NEWSLETTERS =
  "LOAD_MORE_GET_SUBSCRIBED_NEWSLETTERS";
export const CREATE_MY_NEWSLETTERS = "CREATE_MY_NEWSLETTERS";
export const SUBSCRIBE_MY_NEWSLETTERS = "SUBSCRIBE_MY_NEWSLETTERS";
export const UNSUBSCRIBE_MY_NEWSLETTERS = "UNSUBSCRIBE_MY_NEWSLETTERS";
export const GET_NEWSLETTER_DETAIL = "GET_NEWSLETTER_DETAIL";
export const SUBSCRIBE_DETAILED_NEWSLETTER = "SUBSCRIBE_DETAILED_NEWSLETTER";
export const EDIT_MY_NEWSLETTER = "EDIT_MY_NEWSLETTER";
export const EDIT_MY_NEWSLETTER_DETAILS = "EDIT_MY_NEWSLETTER_DETAILS";
export const GET_DRAFT_NEWSLETTER = "GET_DRAFT_NEWSLETTER";
export const LOAD_MORE_GET_DRAFT_NEWSLETTER = "LOAD_MORE_GET_DRAFT_NEWSLETTER";
export const GET_SUGGESTED_EVENTS = "GET_SUGGESTED_EVENTS";
export const ATTEND_EVENTS = "ATTEND_EVENTS";
export const UNATTEND_EVENTS = "UNATTEND_EVENTS";
export const CANCEL_EVENT = "CANCEL_EVENT";
export const PIN_EVENTS = "PIN_EVENTS";
export const UNPIN_EVENTS = "UNPIN_EVENTS";
export const GET_PINNED_EVENTS = "GET_PINNED_EVENTS";
export const PIN_EVENTS_RELATED = "PIN_EVENTS_RELATED";
export const GET_UPCOMING_EVENTS = "GET_UPCOMING_EVENTS";
export const GET_HISTORY_EVENTS = "GET_HISTORY_EVENTS";
export const GET_EVENT_DETAIL = "GET_EVENT_DETAIL";
export const CREATE_MY_EVENT = "CREATE_MY_EVENT";
export const GET_MY_EVENTS = "GET_MY_EVENTS";
export const GET_PAGE_EVENTS = "GET_PAGE_EVENTS";
export const EDIT_PAGE_EVENTS = "EDIT_PAGE_EVENTS";
export const GET_MY_DRAFTS = "GET_MY_DRAFTS";
export const EDIT_MY_EVENT = "EDIT_MY_EVENT";
export const DRAFT_MY_EVENT = "DRAFT_MY_EVENT";
export const EDIT_MY_EVENT_DETAILS = "EDIT_MY_EVENT_DETAILS";
export const SHARE_MY_CONTENT = "SHARE_MY_CONTENT";
export const GET_SUGGESTED_GROUPS = "GET_SUGGESTED_GROUPS";
export const LOAD_MORE_GET_SUGGESTED_GROUPS = "LOAD_MORE_GET_SUGGESTED_GROUPS";
export const GET_JOINED_GROUPS = "GET_JOINED_GROUPS";
export const LOAD_MORE_GET_JOINED_GROUPS = "LOAD_MORE_GET_JOINED_GROUPS";
export const GET_PENDING_GROUPS = "GET_PENDING_GROUPS";
export const GET_RECIEVED_GROUPS = "GET_RECIEVED_GROUPS";
export const LOAD_MORE_GET_PENDING_GROUPS = "LOAD_MORE_GET_PENDING_GROUPS";
export const LOAD_MORE_GET_RECIEVED_GROUPS = "LOAD_MORE_GET_RECIEVED_GROUPS";
export const GET_MY_GROUPS = "GET_MY_GROUPS";
export const LOAD_MORE_GET_MY_GROUPS = "LOAD_MORE_GET_MY_GROUPS";
export const CREATE_MY_GROUP = "CREATE_MY_GROUP";
export const EDIT_MY_GROUP = "EDIT_MY_GROUP";
export const GET_GROUP_DETAIL = "GET_GROUP_DETAIL";
export const JOIN_GROUP = "JOIN_GROUP";
export const ACCEPET_GROUP = "ACCEPET_GROUP";
export const UNJOIN_GROUP = "UNJOIN_GROUP";
export const JOIN_GROUP_FROM_DETAIL = "JOIN_GROUP_FROM_DETAIL";
export const UNJOIN_GROUP_FROM_DETAIL = "UNJOIN_GROUP_FROM_DETAIL";
export const WITHDRAW_GROUP_REQUEST = "WITHDRAW_GROUP_REQUEST";
export const LEAVE_GROUP = "LEAVE_GROUP";
export const INITIALIZE_THE_LOADER = "INITIALIZE_THE_LOADER";
export const STOP_THE_LOADER = "STOP_THE_LOADER";
export const INITIALIZE_THE_PAGE_LOADER = "INITIALIZE_THE_PAGE_LOADER";
export const STOP_THE_PAGE_LOADER = "STOP_THE_PAGE_LOADER";
export const GET_SUGGESTED_ARTICLES = "GET_SUGGESTED_ARTICLES";
export const GET_PAGE_ARTICLES = "GET_PAGE_ARTICLES";
export const GET_INDUSTRY_DATA = "GET_INDUSTRY_DATA";
export const GET_MY_ARTICLES = "GET_MY_ARTICLES";
export const GET_DRAFT_ARTICLE = "GET_DRAFT_ARTICLE";
export const GET_ARTICLE_DETAIL = "GET_ARTICLE_DETAIL";
export const CREATE_MY_ARTICLE = "CREATE_MY_ARTICLE";
export const EDIT_MY_ARTICLE = "EDIT_MY_ARTICLE";
export const EDIT_MY_ARTICLE_DETAILS = "EDIT_MY_ARTICLE_DETAILS";
export const LOAD_MORE_MY_ARTICLES = "LOAD_MORE_MY_ARTICLES";
export const LOAD_MORE_SUGGESTED_ARTICLES = "LOAD_MORE_SUGGESTED_ARTICLES";
export const LOAD_MORE_DRAFT_ARTICLE = "LOAD_MORE_DRAFT_ARTICLE";
export const LIKE_ARTICLE = "LIKE_ARTICLE";
export const UNLIKE_ARTICLE = "UNLIKE_ARTICLE";
export const COMMENT_ARTICLE = "COMMENT_ARTICLE";
export const GET_ARTICLE_COMMENT_LIST = "GET_ARTICLE_COMMENT_LIST";
export const GET_MORE_ARTICLE_COMMENT_LIST = "GET_MORE_ARTICLE_COMMENT_LIST";
export const GET_SUGGESTEDTAG_LIST = "GET_SUGGESTEDTAG_LIST";
export const GET_FOLLOWEDTAG_LIST = "GET_FOLLOWEDTAG_LIST";
export const LOAD_MORE_FOLLOWEDTAG_LIST = "LOAD_MORE_FOLLOWEDTAG_LIST";
export const GET_MYTAG_LIST = "GET_MYTAG_LIST";
export const GET_MORE_MYTAG_LIST = "GET_MORE_MYTAG_LIST";
export const GET_ALL_USER_FAIL = "GET_ALL_USER_FAIL";
export const GET_ALL_USER = "GET_ALL_USER";
export const GET_ALL_USER_FILTERED_FAIL = "GET_ALL_USER_FILTERED_FAIL";
export const GET_ALL_USER_FILTERED = "GET_ALL_USER_FILTERED";
export const FAIL_POST_POLL = "FAIL_POST_POLL";
export const POST_POLL = "POST_POLL";
export const GET_CELEBRATION_TYPES = "GET_CELEBRATION_TYPES";
export const GET_SETTING_DATA = "GET_SETTING_DATA";
export const FAIL_GET_SETTING_DATA = "FAIL_GET_SETTING_DATA";
export const UPDATE_SETTING_DATA = "UPDATE_SETTING_DATA";
export const UPDATE_SETTING_DATA_BADGES = "UPDATE_SETTING_DATA_BADGES";
export const ADD_EXPERIENCE_DATA = "ADD_EXPERIENCE_DATA";
export const UPDATE_EXPERIENCE_DATA = "UPDATE_EXPERIENCE_DATA";
export const GET_LANGUAGE_LIST = "GET_LANGUAGE_LIST";
export const GET_CONTENT_LANGUAGE_LIST = "GET_CONTENT_LANGUAGE_LIST";
export const FAIL_GET_LANGUAGE_LIST = "FAIL_GET_LANGUAGE_LIST";
export const BLOG_GET_ALL_BLOGS = "BLOG_GET_ALL_BLOGS";
export const BLOG_GET_ALL_BY_ID = "BLOG_GET_ALL_BY_ID";
export const BLOG_GET_CATEGORY = "BLOG_GET_CATEGORY";
export const EXP_DATA = "EXP_DATA";
export const EDU_DATA = "EDU_DATA";
export const GET_POST_DATA_DETAIL = "GET_POST_DATA_DETAIL";
export const CREATE_POST_SUCCESS = "CREATE_POST_SUCCESS";
export const CREATE_POST_FAIL = "CREATE_POST_FAIL";
export const UPDATE_POST_SUCCESS = "UPDATE_POST_SUCCESS";
export const UPDATE_POST_FAIL = "UPDATE_POST_FAIL";
export const DELETE_POST_SUCCESS = "DELETE_POST_SUCCESS";
export const DELETE_POST_FAIL = "DELETE_POST_FAIL";
export const GET_POST_DETAILS = "GET_POST_DETAILS";
export const FAIL_GET_POST_DETAILS = "FAIL_GET_POST_DETAILS";
export const HELP_SUPPORT_CONTACT = "HELP_SUPPORT_CONTACT";
export const HELP_SUPPORT_CONTACT_FAIL = "HELP_SUPPORT_CONTACT_FAIL";
export const HELP_FAQ_FEEDBACK = "HELP_FAQ_FEEDBACK";
export const GET_CONNECTIONS_SUCCESS = "GET_CONNECTIONS_SUCCESS";
export const GET_CONNECTIONS_FAIL = "GET_CONNECTIONS_FAIL";
export const GET_FOLLOWERS_SUCCESS = "GET_FOLLOWERS_SUCCESS";
export const GET_FOLLOWERS_FAIL = "GET_FOLLOWERS_FAIL";
export const GET_FOLLOWING_SUCCESS = "GET_FOLLOWING_SUCCESS";
export const GET_FOLLOWING_FAIL = "GET_FOLLOWING_FAIL";
export const GET_LOCATIONS_SUCCESS = "GET_LOCATIONS_SUCCESS";
export const GET_LOCATIONS_FAIL = "GET_LOCATIONS_FAIL";
export const GET_FILTER_SUCCESS = "GET_FILTER_SUCCESS";
export const GET_FILTER_FAIL = "GET_FILTER_FAIL";
export const GET_SCHOOL_SUCCESS = "GET_SCHOOL_SUCCESS";
export const GET_SCHOOL_FAIL = "GET_SCHOOL_FAIL";
export const GET_CURRENT_COMPANY_SUCCESS = "GET_CURRENT_COMPANY_SUCCESS";
export const GET_CURRENT_COMPANY_FAIL = "GET_CURRENT_COMPANY_FAIL";
export const GET_VIEW_PROFILE_LIST = "GET_VIEW_PROFILE_LIST";
export const GET_VIEW_PROFILE_COUNT = "GET_VIEW_PROFILE_COUNT";
export const GET_ALL_ACTIVITY = "GET_ALL_ACTIVITY";
export const GET_ALL_ARTICLES = "GET_ALL_ARTICLES";
export const GET_ACTIVITY_MEDIA = "GET_ACTIVITY_MEDIA";
export const GET_ALL_POSTS = "GET_ALL_POSTS";
export const GET_ALL_PAGES_SUCCESS = "GET_ALL_PAGES_SUCCESS";
export const GET_ALL_PAGES_FAIL = "GET_ALL_PAGES_FAIL";
export const UPDATE_PAGE_SUCCESS = "UPDATE_PAGE_SUCCESS";
export const UPDATE_PAGE_FAIL = "UPDATE_PAGE_FAIL";
export const CREATE_PAGE_SUCCESS = "CREATE_PAGE_SUCCESS";
export const FOLLOW_PAGE_SUCCESS = "FOLLOW_PAGE_SUCCESS";
export const UNFOLLOW_PAGE_SUCCESS = "UNFOLLOW_PAGE_SUCCESS";
export const VOTES_RESULT = "VOTES_RESULT";
export const VOTES_RESULT_FAIL = "VOTES_RESULT_FAIL";
export const MERGE_SHARE_DATA = "MERGE_SHARE_DATA";
export const SAVE_POST = "SAVE_POST";
export const SAVE_POST_FAIL = "SAVE_POST_FAIL";
export const FILTER_UPDATE_MY_POST_LIST = "FILTER_UPDATE_MY_POST_LIST";
export const DELETE_SAVED_POST = "DELETE_SAVED_POST";
export const DELETE_SAVED_POST_FAIL = "DELETE_SAVED_POST_FAIL";
export const GET_PAGE_DETAILS = "GET_PAGE_DETAILS";
export const GET_SINGLE_PAGES_SUCCESS = "GET_SINGLE_PAGES_SUCCESS";
export const GET_PAGE_DETAILS_FAIL = "GET_PAGE_DETAILS_FAIL";
export const GET_HASHTAG_LIST = "GET_HASHTAG_LIST";
export const FOLLOW_HASHTAG = "FOLLOW_HASHTAG";
export const LOAD_MORE_SUGGESTEDTAG_LIST = "LOAD_MORE_SUGGESTEDTAG_LIST";
export const GET_HASHTAG_POST_FEED = "GET_HASHTAG_POST_FEED";
export const FOLLOW_HASHTAG_POST_FEED = "FOLLOW_HASHTAG_POST_FEED";
export const UNFOLLOW_HASHTAG_POST_FEED = "UNFOLLOW_HASHTAG_POST_FEED";
export const SEND_CONNECTION_REQUEST = "SEND_CONNECTION_REQUEST";
export const SEND_CONNECT_REQUEST = "SEND_CONNECT_REQUEST";
export const CREATE_PROFILE_FOLLOWER = "CREATE_PROFILE_FOLLOWER";
export const CREATE_PROFILE_UNFOLLOWER = "CREATE_PROFILE_UNFOLLOWER";
export const ADD_COMMENT = "ADD_COMMENT";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const ADD_COMMENT_REPLY = "ADD_COMMENT_REPLY";
export const PIN_COMMENT = "PIN_COMMENT";
export const ADD_COMMENT_FAIL = "ADD_COMMENT_FAIL";
export const GET_COMMENT = "GET_COMMENT";
export const ADD_MORE_COMMENT = "ADD_MORE_COMMENT";
export const GET_MORE_COMMENT_REPLY = "GET_MORE_COMMENT_REPLY";
export const DELETE_COMMENT_REPLY = "DELETE_COMMENT_REPLY";
export const DELETE_REACTION_COMMENT_DATA = "DELETE_REACTION_COMMENT_DATA";
export const DELETE_REACTION_REPLY_DATA = "DELETE_REACTION_REPLY_DATA";
export const GET_COMMENT_FAIL = "GET_COMMENT_FAIL";
export const GET_REPORT_TYPE = "GET_REPORT_TYPE";
export const SUBMIT_REPORT = "SUBMIT_REPORT";
export const HIDE_USER_POST = "HIDE_USER_POST";
export const REPOST_POST = "REPOST_POST";
export const FAIL_GET_REPORT_TYPE = "FAIL_GET_REPORT_TYPE";
export const ADD_HONORS_DATA = "ADD_HONORS_DATA";
export const UPDATE_HONORS_DATA = "UPDATE_HONORS_DATA";
export const GET_SUGGESTED_PEOPLE = "GET_SUGGESTED_PEOPLE";
export const GET_MORE_SUGGESTED_PEOPLE = "GET_MORE_SUGGESTED_PEOPLE";
export const GET_PENDING_INVITATIONS = "GET_PENDING_INVITATIONS";
export const GET_SENT_INVITATIONS_PENDING = "GET_SENT_INVITATIONS_PENDING";
export const WITHDRAW_CONNECTION_REQUEST = "WITHDRAW_CONNECTION_REQUEST";
export const GET_MY_CONNECTION = "GET_MY_CONNECTION";
export const ACCEPT_CONNECTION_REQUEST = "ACCEPT_CONNECTION_REQUEST";
export const REJECT_CONNECTION_REQUEST = "REJECT_CONNECTION_REQUEST";
export const LIKE_NEWSLETTER = "LIKE_NEWSLETTER";
export const UNLIKE_NEWSLETTER = "UNLIKE_NEWSLETTER";
export const COMMENT_NEWSLETTER = "COMMENT_NEWSLETTER";
export const GET_COMMENT_LIST = "GET_COMMENT_LIST";
export const GET_MORE_NEWSLETTER_COMMENT_LIST =
  "GET_MORE_NEWSLETTER_COMMENT_LIST";
export const LIKE_REACTION_DATA = "LIKE_REACTION_DATA";
export const DELETE_REACTION_DATA = "DELETE_REACTION_DATA";
export const LIKE_REACTION_COMMENT_DATA = "LIKE_REACTION_COMMENT_DATA";
export const LIKE_REACTION_REPLY_DATA = "LIKE_REACTION_REPLY_DATA";
export const SEND_PAGE_INVITATION = "SEND_PAGE_INVITATION";
export const ADD_PAGE_ADMIN = "ADD_PAGE_ADMIN";
export const GET_PAGE_ADMINS = "GET_PAGE_ADMINS";
export const GET_PAGE_FOLLOWERS = "GET_PAGE_FOLLOWERS";
export const DELETE_PAGE_ADMIN = "DELETE_PAGE_ADMIN";
export const GET_RECEIVED_RECOMMENDATION = "GET_RECEIVED_RECOMMENDATION";
export const GET_GIVEN_RECOMMENDATION = "GET_GIVEN_RECOMMENDATION";
export const ADD_RECOMMENDATION = "ADD_RECOMMENDATION";
export const TRANSFER_PAGE_ADMIN = "TRANSFER_PAGE_ADMIN";
export const PASS_VERIFY_SUCCESS = "PASS_VERIFY_SUCCESS";
export const ADD_PAGE_URL = "ADD_PAGE_URL";
export const ADD_GROUP_URL = "ADD_GROUP_URL";
export const GET_PAGE_INSIGHTS = "GET_PAGE_INSIGHTS";
export const UPLOAD_INTRO_VIDEO = "UPLOAD_INTRO_VIDEO";
export const UPLOAD_RESUME_DATA = "UPLOAD_RESUME_DATA";
export const DELETE_INTRO_VIDEO = "DELETE_INTRO_VIDEO";
export const GET_COMMENT_FAIL_POST = "GET_COMMENT_FAIL_POST";
export const ADD_MORE_COMMENT_POST = "ADD_MORE_COMMENT_POST";
export const GET_COMMENT_POST = "GET_COMMENT_POST";
export const ADD_COMMENT_FAIL_POST = "ADD_COMMENT_FAIL_POST";
export const ADD_COMMENT_POST = "ADD_COMMENT_POST";
export const REACTION_POST_FAIL_POST = "REACTION_POST_FAIL_POST";
export const LIKE_REACTION_DATA_POST = "LIKE_REACTION_DATA_POST";
export const DELETE_REACTION_DATA_POST = "DELETE_REACTION_DATA_POST";
export const LIKE_REACTION_COMMENT_DATA_POST =
  "LIKE_REACTION_COMMENT_DATA_POST";
export const ADD_COMMENT_REPLY_POST = "ADD_COMMENT_REPLY_POST";
export const GET_ALL_COMMENTS_REPLY_POST = "GET_ALL_COMMENTS_REPLY_POST";
export const LIKE_REACTION_REPLY_DATA_POST = "LIKE_REACTION_REPLY_DATA_POST";
export const DELETE_REACTION_COMMENT_DATA_POST =
  "DELETE_REACTION_COMMENT_DATA_POST";
export const UPDATE_HOME_CONNECTION_STATUS = "UPDATE_HOME_CONNECTION_STATUS";
export const USER_FOLLOW_STATUS_HOME = "USER_FOLLOW_STATUS_HOME";
export const USER_UNFOLLOW_STATUS_HOME = "USER_UNFOLLOW_STATUS_HOME";
export const FOLLOW_PAGE_SUCCESS_HOME = "FOLLOW_PAGE_SUCCESS_HOME";
export const UNFOLLOW_PAGE_SUCCESS_HOME = "UNFOLLOW_PAGE_SUCCESS_HOME";
export const REQUEST_SEND_SUCCESS = "REQUEST_SEND_SUCCESS";
export const FOLLOW_PAGE = "FOLLOW_PAGE";
export const UNFOLLOW_PAGE = "UNFOLLOW_PAGE";
export const UPDATE_PROFILE_URL = "UPDATE_PROFILE_URL";
export const FAQ_LIST_SUCCESS = "FAQ_LIST_SUCCESS";
export const FAQ_LIST_FAIL = "FAQ_LIST_FAIL";
export const VERIFY_EMAIL = "VERIFY_EMAIL";
export const CHANGE_EMAIL = "CHANGE_EMAIL";
export const FAQ_SEARCH_SUCCESS = "FAQ_SEARCH_SUCCESS";
export const FAQ_SEARCH_BY_ID_SUCCESS = "FAQ_SEARCH_BY_ID_SUCCESS";
export const COMMENT_STATUS_TRUE = "COMMENT_STATUS_TRUE";
export const COMMENT_STATUS_FALSE = "COMMENT_STATUS_FALSE";
export const REPOST_SUCCESS = "REPOST_SUCCESS";
export const POSTVIEWLOG_SUCCESS = "POSTVIEWLOG_SUCCESS";
export const POSTVIEWCOUNT_SUCCESS = "POSTVIEWCOUNT_SUCCESS";
export const ASK_RECOMMENDATION = "ASK_RECOMMENDATION";
export const UPDATE_RECOMMENDATION = "UPDATE_RECOMMENDATION";
export const DELETE_RECOMMENDATION = "DELETE_RECOMMENDATION";
export const GET_PENDING_RECOMMENDATION = "GET_PENDING_RECOMMENDATION";
export const TOGGLE_UPDATE_RECOMMENDATION = "TOGGLE_UPDATE_RECOMMENDATION";
export const GET_ALL_COMPANY = "GET_ALL_COMPANY";
export const GET_SINGLE_COMPANY = "GET_SINGLE_COMPANY";
export const GET_ALL_MEMBERS = "GET_ALL_MEMBERS";
export const GET_MORE_PUBLIC_MEMBERS = "GET_MORE_PUBLIC_MEMBERS";
export const GET_SINGLE_MEMBER = "GET_SINGLE_MEMBER";
export const GET_PUBLIC_ARTICLE_LIST = "GET_PUBLIC_ARTICLE_LIST";
export const GET_MORE_PUBLIC_ARTICLE_LIST = "GET_MORE_PUBLIC_ARTICLE_LIST";
export const GET_PUBLIC_ARTICLE_DETAILS = "GET_PUBLIC_ARTICLE_DETAILS";
export const GET_PUBLIC_NEWSLETTER_LIST = "GET_PUBLIC_NEWSLETTER_LIST";
export const GET_MORE_PUBLIC_NEWSLETTER_LIST =
  "GET_MORE_PUBLIC_NEWSLETTER_LIST";
export const GET_PUBLIC_NEWSLETTER_DETAILS = "GET_PUBLIC_NEWSLETTER_DETAILS";
export const GET_PUBLIC_POST_LIST = "GET_PUBLIC_POST_LIST";
export const GET_MORE_PUBLIC_POST_LIST = "GET_MORE_PUBLIC_POST_LIST";
export const GET_PUBLIC_POST_DETAILS = "GET_PUBLIC_POST_DETAILS";
export const GET_PUBLIC_JOB_LIST = "GET_PUBLIC_JOB_LIST";
export const GET_MORE_PUBLIC_JOB_LIST = "GET_MORE_PUBLIC_JOB_LIST";
export const GET_PUBLIC_JOB_DETAILS = "GET_PUBLIC_JOB_DETAILS";
export const DELETE_COMMENT_WEBVIEW = "DELETE_COMMENT_WEBVIEW";
export const BLOCK_COMMENT = "BLOCK_COMMENT";
export const PIN_COMMENT_POSTDETAIL = "PIN_COMMENT_POSTDETAIL";
export const UPDATE_COMMENT_POSTDETAIL = "UPDATE_COMMENT_POSTDETAIL";
export const UPDATE_COMMENT = "UPDATE_COMMENT";
export const UPDATE_REPLY_POSTDETAIL = "UPDATE_REPLY_POSTDETAIL";
export const UNPIN_COMMENT = "UNPIN_COMMENT";
export const REMOVE_CONNECTION = "REMOVE_CONNECTION";
export const GET_PAGES_PERMISSION = "GET_PAGES_PERMISSION";
export const POST_CAMPAIGN_DATA = "POST_CAMPAIGN_DATA";
export const GET_CAMPAIGN_LIST = "GET_CAMPAIGN_LIST";
export const LOAD_MORE_CAMPAIGNS = "LOAD_MORE_CAMPAIGNS";
export const GET_FEATURED_CAMPAIGN_LIST = "GET_FEATURED_CAMPAIGN_LIST";
export const LOAD_FEATURED_MORE_CAMPAIGNS = "LOAD_FEATURED_MORE_CAMPAIGNS";
export const POST_LEAD_DATA = "POST_LEAD_DATA";
export const GET_LEAD_LIST = "GET_LEAD_LIST";
export const ADD_LEAD_GROUP = "ADD_LEAD_GROUP";
export const ADD_LEAD_ACTIONS = "ADD_LEAD_ACTIONS";
export const GET_LEADS_ACTION_LIST = "GET_LEADS_ACTION_LIST";
export const EDIT_LEAD_DATA = "EDIT_LEAD_DATA";
export const DELETE_LEAD_DATA = "DELETE_LEAD_DATA";
export const SEND_LEADS_CONNECTION = "SEND_LEADS_CONNECTION";
export const REFER_MY_FRIEND = "REFER_MY_FRIEND";
export const GET_REFER_DATA = "GET_REFER_DATA";
export const WP_REGISTER_SUCCESS = "WP_REGISTER_SUCCESS";
export const WP_REGISTER_FAIL = "WP_REGISTER_FAIL";
export const WP_LOGIN_SUCCESS = "WP_LOGIN_SUCCESS";
export const WP_LOGIN_FAIL = "WP_LOGIN_FAIL";
export const GET_LANDING_DATA = "GET_LANDING_DATA";
export const GET_MEMBERSHIP_TYPES = "GET_MEMBERSHIP_TYPES";
export const GET_MEMBERSHIP_BY_ID = "GET_MEMBERSHIP_BY_ID";
export const GET_MEMBERSHIP_FEATURES = "GET_MEMBERSHIP_FEATURES";
export const PUBLIC_POSTS_LIST = "PUBLIC_POSTS_LIST";
export const AS_A_PAGE_ROLE = "AS_A_PAGE_ROLE";
export const UPDATE_COMMENT_REPLY = "UPDATE_COMMENT_REPLY";
export const GET_BLOCKED_USERS = "GET_BLOCKED_USERS";
export const UNFOLLOW_SINGLEPAGE_SUCCESS = "UNFOLLOW_SINGLEPAGE_SUCCESS";
export const FOLLOW_SINGLEPAGE_SUCCESS = "FOLLOW_SINGLEPAGE_SUCCESS";
export const DELETE_MEDIA_DATA = "DELETE_MEDIA_DATA";
export const CREATE_JOB_POST_SUCCESS = "CREATE_JOB_POST_SUCCESS";
export const CREATE_JOB_POST_FAIL = "CREATE_JOB_POST_FAIL";
export const CREATE_JOB_POST_LOADING = "CREATE_JOB_POST_LOADING";
export const UPDATE_CONNECTION_REMOVE = "UPDATE_CONNECTION_REMOVE";
export const GET_ALL_JOBS = "GET_ALL_JOBS";
export const LOAD_MORE_GET_ALL_JOBS = "LOAD_MORE_GET_ALL_JOBS";
export const GET_MY_JOBS = "GET_MY_JOBS";
export const LOAD_MORE_GET_MY_JOBS = "LOAD_MORE_GET_MY_JOBS";
export const GET_FEATURED_JOBS = "GET_FEATURED_JOBS";
export const LOAD_MORE_FEATURED_JOBS = "LOAD_MORE_FEATURED_JOBS";
export const GET_ALL_FEATURED_JOBS = "GET_ALL_FEATURED_JOBS";
export const LOAD_MORE__ALL_FEATURED_JOBS = "LOAD_MORE_ALL_FEATURED_JOBS";
export const GET_SAVED_JOBS = "GET_SAVED_JOBS";
export const LOAD_MORE_SAVED_JOBS = "LOAD_MORE_SAVED_JOBS";
export const GET_APPLIED_JOBS = "GET_APPLIED_JOBS";
export const LOAD_MORE_APPLIED_JOBS = "LOAD_MORE_APPLIED_JOBS";
export const GET_SENT_INTERVIEW_JOBS = "GET_SENT_INTERVIEW_JOBS";
export const LOAD_MORE_SENT_INTERVIEW_JOBS = "LOAD_MORE_SENT_INTERVIEW_JOBS";
export const GET_INPROGRESS_JOBS = "GET_INPROGRESS_JOBS";
export const LOAD_MORE_INPROGRESS_JOBS = "LOAD_MORE_INPROGRESS_JOBS";
export const GET_CLOSED_JOBS = "GET_CLOSED_JOBS";
export const LOAD_MORE_CLOSED_JOBS = "LOAD_MORE_CLOSED_JOBS";
export const GET_ALL_JOBS_DETAILS = "GET_ALL_JOBS_DETAILS";
export const GET_MORE_ALL_JOBS = "GET_MORE_ALL_JOBS";
export const UPDATE_JOB_DETAIL_SUCCESS = "UPDATE_JOB_DETAIL_SUCCESS";
export const CLOSE_JOB_SUCCESS = "CLOSE_JOB_SUCCESS";
export const UPDATE_JOB_DESCERPTION = "UPDATE_JOB_DESCERPTION";
export const APPLY_FOR_JOB = "APPLY_FOR_JOB";
export const CREATE_JOB_ALERT = "CREATE_JOB_ALERT";
export const GET_JOB_ALERT = "GET_JOB_ALERT";
export const DELETE_JOB_ALERT = "DELETE_JOB_ALERT";
export const EDIT_JOB_ALERT = "EDIT_JOB_ALERT";
export const UPDATE_JOB_DETAIL_FAIL = "UPDATE_JOB_DETAIL_FAIL";
export const GET_ALL_JOBS_MESSAGES = "GET_ALL_JOBS_MESSAGES";
export const UPDATE_JOB_MESSAGE = "UPDATE_JOB_MESSAGE";
export const SAVE_JOB = "SAVE_JOB";
export const SHORTLIST_CANDIDATE_FOR_JOBS = "SHORTLIST_CANDIDATE_FOR_JOBS";
export const GET_ALL_JOBS_APPLICANTS_DETAILS =
  "GET_ALL_JOBS_APPLICANTS_DETAILS";
export const GET_ALL_APPLICANTS_DETAILS_BYID =
  "GET_ALL_APPLICANTS_DETAILS_BYID";
export const SHORTLISTED_CANDIDATE_LIST = "SHORTLISTED_CANDIDATE_LIST";
export const LOAD_MORE_SHORTLISTED_CANDIDATE_LIST =
  "LOAD_MORE_SHORTLISTED_CANDIDATE_LIST";
export const FAIL_SHORTLISTED_CANDIDATE_LIST =
  "FAIL_SHORTLISTED_CANDIDATE_LIST";
export const SHORTLISTED_VIEW_CANDIDATE_LIST =
  "SHORTLISTED_VIEW_CANDIDATE_LIST";
export const LOAD_MORE_SHORTLISTED_VIEW_CANDIDATE_LIST =
  "LOAD_MORE_SHORTLISTED_VIEW_CANDIDATE_LIST";
export const FAIL_SHORTLISTED_VIEW_CANDIDATE_LIST =
  "FAIL_SHORTLISTED_VIEW_CANDIDATE_LIST";
export const CREATE_JOB_QUESTIONARY = "CREATE_JOB_QUESTIONARY";
export const GET_QUESTIONARY_LIST = "GET_QUESTIONARY_LIST";
export const LOAD_MORE_GET_QUESTIONARY_LIST = "LOAD_MORE_GET_QUESTIONARY_LIST";
export const DELETE_QUESTIONARY_SET = "DELETE_QUESTIONARY_SET";
export const UPDATE_JOB_QUESTIONARY = "UPDATE_JOB_QUESTIONARY";
export const UPDATE_APPLICANTS_SUCCESS = "UPDATE_APPLICANTS_SUCCESS";
export const UPDATE_APPLICANTS_FAIL = "UPDATE_APPLICANTS_FAIL";
export const SEND_SMART_INTERVIEW = "SEND_SMART_INTERVIEW";
export const GET_QUESTIONS = "GET_QUESTIONS";

export const GET_ALL_RECURITER_SUCCESS = "GET_ALL_RECURITER_SUCCESS";
export const GET_CANDIDATE_LIST = "GET_CANDIDATE_LIST";
export const GET_MORE_CANDIDATE_LIST = "GET_MORE_CANDIDATE_LIST";
export const GET_ALL_RECURITER_FAIL = "GET_ALL_RECURITER_FAIL";
export const GET_CANDIDATE_REQUESTED_DATA = "GET_CANDIDATE_REQUESTED_DATA";

export const GET_ALL_MEMBER_SUCCESS = "GET_ALL_MEMBER_SUCCESS";
export const GET_ALL_MEMBER_FAIL = "GET_ALL_MEMBER_FAIL";
export const WITHDRAW_MEMBER_REQUEST = "WITHDRAW_MEMBER_REQUEST";

export const SMART_APPLY_POST = "SMART_APPLY_POST";
export const SMART_APPLY_POST_FAIL = "SMART_APPLY_POST_FAIL";

export const GET_STATISTICS = "GET_STATISTICS";
export const GET_MORE_STATISTICS = "GET_MORE_STATISTICS";

export const DELETE_LAN_DATA = "DELETE_LAN_DATA";
export const DELETE_HON_DATA = "DELETE_HON_DATA";

export const JOB_PREFERENCE = "JOB_PREFERENCE";
export const SEARCH_HISTORY = "SEARCH_HISTORY";
export const MORE_SEARCH_HISTORY = "MORE_SEARCH_HISTORY";
export const DELETE_SEARCH_HISTORY = "DELETE_SEARCH_HISTORY";
export const CLEAR_SEARCH_HISTORY = "CLEAR_SEARCH_HISTORY";
export const GET_SECONDARY_EMAIL = "GET_SECONDARY_EMAIL";
export const GET_SESSION_LIST = "GET_SESSION_LIST";
export const UPDATE_JOB_FORM = "UPDATE_JOB_FORM";
export const GET_TALENT_POOL_DETAIL = "GET_TALENT_POOL_DETAIL";
export const GET_TALENT_POOL_DETAIL_FAIL = "GET_TALENT_POOL_DETAIL_FAIL";
export const GET_TALENT_POOL_DETAIL_BY_TOKEN =
  "GET_TALENT_POOL_DETAIL_BY_TOKEN";
export const GET_TALENT_POOL_DETAIL_BY_TOKEN_FAIL =
  "GET_TALENT_POOL_DETAIL_BY_TOKEN_FAIL";

export const CREATE_TALENT_POOL = "CREATE_TALENT_POOL";
export const GET_TALENT_POOL = "GET_TALENT_POOL";
export const UPDATE_TALENT_POOL = "UPDATE_TALENT_POOL";
export const GET_APPLICANTS = "GET_APPLICANTS";
export const UPDATE_APPLICANTS = "UPDATE_APPLICANTS";
export const SUBSCRIBE_FOR_JOB = "SUBSCRIBE_FOR_JOB";
export const GET_COMPANY_JOBS = "GET_COMPANY_JOBS";
export const LOAD_MORE_GET_COMPANY_JOBS = "LOAD_MORE_GET_COMPANY_JOBS";
export const UPDATE_JOB = "UPDATE_JOB";
export const CLOSE_JOB = "CLOSE_JOB";
export const GET_ALL_NOTIFICATIONS = "GET_ALL_NOTIFICATIONS";
export const LOAD_MORE_GET_ALL_NOTIFICATIONS =
  "LOAD_MORE_GET_ALL_NOTIFICATIONS";
export const GET_UNREAD_NOTIFICATIONS = "GET_UNREAD_NOTIFICATIONS";
export const LOAD_MORE_UNREAD_NOTIFICATIONS = "LOAD_MORE_UNREAD_NOTIFICATIONS";
export const UPDATE_READ_STATUS_NOTIFICATIONS =
  "UPDATE_READ_STATUS_NOTIFICATIONS";
export const UPDATE_PINNED_STATUS_NOTIFICATIONS =
  "UPDATE_PINNED_STATUS_NOTIFICATIONS";
export const DELETE_NOTIFICATIONS = "DELETE_NOTIFICATIONS";
export const UPDATE_ALL_AS_READ_NOTIFICATIONS =
  "UPDATE_ALL_AS_READ_NOTIFICATIONS";
export const UPDATE_ALL_AS_UNREAD_NOTIFICATIONS =
  "UPDATE_ALL_AS_UNREAD_NOTIFICATIONS";
export const UPDATE_APPLICANTS_BY_ID = "UPDATE_APPLICANTS_BY_ID";
export const GET_MEMBER_LIST_SUCCESS = "GET_MEMBER_LIST_SUCCESS";
export const GET_SINGLE_MEMBER_DATA = "GET_SINGLE_MEMBER_DATA";
export const LOAD_MORE_MEMBER_LIST_SUCCESS = "LOAD_MORE_MEMBER_LIST_SUCCESS";
export const ADD_OTHER_INFO = "ADD_OTHER_INFO";
export const ADD_EMPLOYMENT = "ADD_EMPLOYMENT";
export const ADD_EDUCATION = "ADD_EDUCATION";
export const ADD_CRIMINAL = "ADD_CRIMINAL";
export const UPDATE_BASIC_INFO = "UPDATE_BASIC_INFO";
export const UPDATE_EMPLOYMENT = "UPDATE_EMPLOYMENT";
export const UPDATE_EDUCATION = "UPDATE_EDUCATION";
export const UPDATE_CRIMINAL = "UPDATE_CRIMINAL";
export const UPDATE_AUTHORIZATION = "UPDATE_AUTHORIZATION";
export const REMOVE_OTHER_INFO = "REMOVE_OTHER_INFO";
export const REMOVE_PERTICULAR_EMPLOYMENT = "REMOVE_PERTICULAR_EMPLOYMENT";
export const REMOVE_PERTICULAR_EDUCATION = "REMOVE_PERTICULAR_EDUCATION";
export const REMOVE_ALL_EMPLOYMENT = "REMOVE_ALL_EMPLOYMENT";
export const REMOVE_ALL_EDUCATION = "REMOVE_ALL_EDUCATION";
export const REMOVE_CRIMINAL = "REMOVE_CRIMINAL";
export const CLEAR_DATA = "CLEAR_DATA";
export const UPDATE_CONVERSATION = "UPDATE_CONVERSATION";
export const CREATE_CONVERSATION = "CREATE_CONVERSATION";
export const GET_CONVERSATION = "GET_CONVERSATION";
export const REMOVE_CONVERSATION = "REMOVE_CONVERSATION";
export const UPDATE_UNREAD = "UPDATE_UNREAD";
export const UPDATE_GROUP_CONVERSATION = "UPDATE_GROUP_CONVERSATION";
export const CREATE_GROUP_CONVERSATION = "CREATE_GROUP_CONVERSATION";
export const GET_GROUP_CONVERSATION = "GET_GROUP_CONVERSATION";
export const REMOVE_GROUP_CONVERSATION = "REMOVE_GROUP_CONVERSATION";
export const POST_NOW_PREVIEW = "POST_NOW_PREVIEW";
export const VERIFY_PORTAL_SUCCESS = "VERIFY_PORTAL_SUCCESS";
export const GET_UNFOLLOWED_USERS = "GET_UNFOLLOWED_USERS";
export const FOOTER_FIRST = "FOOTER_FIRST";
export const FOOTER_SECOND = "FOOTER_SECOND";
export const FOOTER_THIRD = "FOOTER_THIRD";
export const INIT_SETTING_PAGE_LOADER = "INIT_SETTING_PAGE_LOADER";
export const STOP_SETTING_PAGE_LOADER = "STOP_SETTING_PAGE_LOADER";
export const EDITOR_IMAGE_UPDATE_URL = "EDITOR_IMAGE_UPDATE_URL";
export const EDITOR_IMAGE_CLEAR_URL = "EDITOR_IMAGE_CLEAR_URL";
export const DELETE_LEAD_GROUP = "DELETE_LEAD_GROUP";
export const EDIT_LEAD_GROUP = "EDIT_LEAD_GROUP";
export const EDITOR_IMAGE_UPDATE_URL_NEWSLETTER =
  "EDITOR_IMAGE_UPDATE_URL_NEWSLETTER";
export const GET_ALL_PRODUCT_LIST = "GET_ALL_PRODUCT_LIST";
export const CREATE_PAGE_PRODUCT = "CREATE_PAGE_PRODUCT";
export const UPDATE_PAGE_PRODUCT = "UPDATE_PAGE_PRODUCT";
export const GET_RECENT_SEARCH = "GET_RECENT_SEARCH";
export const CLEAR_SUGGESTED_GROUPS = "CLEAR_SUGGESTED_GROUPS";
export const UPDATE_MEMBERSHIP_DATA = "UPDATE_MEMBERSHIP_DATA";
export const FETCH_ALL_RESUME_TYPES_PDF_REQUEST =
  "FETCH_ALL_RESUME_TYPES_PDF_REQUEST";
export const FETCH_ALL_RESUME_TYPES_PDF_SUCCESS =
  "FETCH_ALL_RESUME_TYPES_PDF_SUCCESS";
export const FETCH_ALL_RESUME_TYPES_PDF_FAILURE =
  "FETCH_ALL_RESUME_TYPES_PDF_FAILURE";
export const FETCH_RESUME_PDF_REQUEST = "FETCH_RESUME_PDF_REQUEST";
export const FETCH_RESUME_PDF_SUCCESS = "FETCH_RESUME_PDF_SUCCESS";
export const FETCH_RESUME_PDF_FAILURE = "FETCH_RESUME_PDF_FAILURE";

export const UPDATE_BASIC_INFO_IN_CV = "UPDATE_BASIC_INFO_IN_CV";
export const ADD_WORK_EXPERIENCE_IN_CV = "ADD_WORK_EXPERIENCE_IN_CV";
export const EDIT_WORK_EXPERIENCE_IN_CV = "EDIT_WORK_EXPERIENCE_IN_CV";
export const ADD_EDUCATION_IN_CV = "ADD_EDUCATION_IN_CV";
export const EDIT_EDUCATION_IN_CV = "EDIT_EDUCATION_IN_CV";
export const ADD_HONORS_IN_CV = "ADD_HONORS_IN_CV;";
export const EDIT_HONORS_IN_CV = "EDIT_HONORS_IN_CV";
export const ADD_LANGUAGE_IN_CV = "ADD_LANGUAGE_IN_CV";
export const EDIT_LANGUAGE_IN_CV = "EDIT_LANGUAGE_IN_CV";
export const ADD_SKILLS_IN_CV = "ADD_SKILLS_IN_CV";
export const EDIT_SKILLS_IN_CV = "EDIT_SKILLS_IN_CV";
export const DELETE_WORK_EXPERIENCE_IN_CV = "DELETE_WORK_EXPERIENCE_IN_CV";
export const DELETE_EDUCATION_IN_CV = "DELETE_EDUCATION_IN_CV";
export const DELETE_HONORS_IN_CV = "DELETE_HONORS_IN_CV";
export const DELETE_LANGUAGE_IN_CV = "DELETE_LANGUAGE_IN_CV";
export const FILL_INITIAL_DATA_IN_CV = "FILL_INITIAL_DATA_IN_CV";
export const GET_SCREENING_LIST_SUCCESS = "GET_SCREENING_LIST_SUCCESS";
export const GET_AD_LIST = "GET_AD_LIST";
export const DELETE_CAMPAIGN = "DELETE_CAMPAIGN";
export const ADD_JOB_FEEDBACK = "ADD_JOB_FEEDBACK";
export const ADD_INTEREST_IN_CV = "ADD_INTEREST_IN_CV";
export const EDIT_INTEREST_IN_CV = "EDIT_INTEREST_IN_CV";
export const ADD_MULTIPLE_URL = "ADD_MULTIPLE_URL";
export const DELETE_RESUME = "DELETE_RESUME";
export const UPDATE_GROUP_MEMBER = "UPDATE_GROUP_MEMBER";
export const REMOVE_GROUP_MEMBER = "REMOVE_GROUP_MEMBER";
export const UPDATE_CONVERSATION_TYPE = "UPDATE_CONVERSATION_TYPE";
