import { call } from "./apiCall";
import { callChat } from "./apiCallChat";

class MessageAPI {
  createConversation(data) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await callChat("post", `api/v1/rooms/create`, null, data);
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  updateConversation(data) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await callChat("post", `api/v1/rooms/update`, null, data);
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  deleteConversation(data) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await callChat("post", `api/v1/rooms/delete`, null, data);
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  updateGroupConversation(data) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "patch",
            `api/v1/message/group-conversation`,
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getConversation(data) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await callChat("get", `api/v1/rooms`, data, null);
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getMessages(data) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await callChat("get", `api/v1/messages`, data, null);
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  updateConversationType(data) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "patch",
            `api/v1/message/conversation-type`,
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  updateAwayMessage(userId, data) {
    return new Promise((resolve, reject) => {
      // const token = localStorage.getItem("accessToken");
      (async () => {
        try {
          // const res = await call(
          //   "post",
          //   `api/v1/message/set-away-message`,
          //   null,
          //   data,
          //   token
          // );
          const res = await callChat(
            "patch",
            `api/v1/users/user_id-${userId}`,
            null,
            data
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getAwayMessage(userId) {
    return new Promise((resolve, reject) => {
      // const token = localStorage.getItem("accessToken");
      (async () => {
        try {
          // const res = await call(
          //   "get",
          //   `api/v1/message/away-message`,
          //   null,
          //   null,
          //   token
          // );
          const res = await callChat(
            "get",
            `api/v1/user/user_id-${userId}`,
            null,
            null
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  addMemeber(data) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await callChat(
            "post",
            `api/v1/rooms/add-member`,
            null,
            data
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  removeMemeber(data) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await callChat(
            "delete",
            `api/v1/rooms/remove-member`,
            data
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }
}

export const messageAPI = new MessageAPI();
