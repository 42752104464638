import React from "react";
import logo from "../../../../assets/img/np-white-logo.svg";
import "./Header.scss";
import exploreIcon from "../../../../assets/img/explorehome-icon.svg";
import mobileIcon from "../../../../assets/img/mobilehome-icon.svg";
import memberIcon from "../../../../assets/img/memberhome-icon.svg";
import blogIcon from "../../../../assets/img/bloghome-icon.svg";
import { Link } from "react-router-dom";
import navIcon1 from "../../../../assets/img/navIcon1.png";
import navIcon2 from "../../../../assets/img/navIcon2.png";
import navIcon3 from "../../../../assets/img/navIcon3.png";
import workerpoolIcon from "../../../../assets/img/WorkerPoolIconUpdated.svg";

export default function Header() {
  return (
    <div className="login login-header">
      <nav className="navbar navbar-expand-lg navbar-light bg-blue">
        <div className="container align-items-start">
          <Link to="/" className="navbar-brand">
            <img src={logo} alt="" className="navbar-brand-image" />
          </Link>
          <button
            type="button"
            className="navbar-toggler"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            {/* <span className="navbar-toggler-icon"></span> */}
            <span></span>
            <span></span>
            <span></span>
          </button>
          <div className="nav-for-desktop" id="">
            <div className="navbar-nav ms-auto">
              <div className="d-flex align-items-center gap-2_5">
                <img
                  src={exploreIcon}
                  className="navbar__link-icon"
                  alt=""
                  style={{ height: "24px", width: "24px" }}
                />
                <Link to="/explore" className="nav-item nav-link">
                  Explore
                </Link>
              </div>
              <div className="d-flex align-items-center gap-2_5">
                <img src={mobileIcon} className="navbar__link-icon" alt="" />
                <Link to="/mobile" className="nav-item nav-link">
                  Mobile
                </Link>
              </div>

              <div className="d-flex align-items-center gap-2_5">
                <img src={memberIcon} className="navbar__link-icon" alt="" />
                <Link to="/membership" className="nav-item nav-link">
                  Become a member
                </Link>
              </div>
              <div className="d-flex align-items-center gap-2_5">
                <img src={blogIcon} className="navbar__link-icon" alt="" />
                <Link to="/blog" className="nav-item nav-link">
                  Blogs
                </Link>
              </div>

              <div>
                <Link
                  to="https://nobelpageworkerpoolstage.dev.vinove.com/"
                  className="hyperLink nav-link"
                  target="_blank"
                >
                  <img
                    src={workerpoolIcon}
                    className="navbar__link-icon"
                    alt=""
                    style={{ height: "24px", width: "24px" }}
                  />
                  WorkerPool
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <div
        className="nav-for-mobile collapse navbar-collapse"
        id="navbarCollapse"
      >
        <div className="mobileNavFrame">
          <ul>
            <li>
              <Link to="explore">
                <img src={navIcon1} alt="Icon" /> Explore
              </Link>
            </li>
            <li>
              <Link to="membership">
                <img src={navIcon2} alt="Icon" /> Become a member
              </Link>
            </li>
            <li>
              <Link to="blog">
                <img src={navIcon3} alt="Icon" /> Blogs
              </Link>
            </li>
            <li>
              <Link to="/">General</Link>
              <ul className="sub-links">
                <li>
                  <Link to="/">Login</Link>
                </li>
                <li>
                  <Link to="signup">Sign Up</Link>
                </li>
                <li>
                  <Link to="/">Contact</Link>
                </li>
                <li>
                  <Link to="/">Careers</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/">Browse NobelPage</Link>
              <ul className="sub-links">
                <li>
                  <Link to="/">Salary</Link>
                </li>
                <li>
                  <Link to="/">Services</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="directory">Directories</Link>
              <ul className="sub-links">
                <li>
                  <Link to="/">Members</Link>
                </li>
                <li>
                  <Link to="/">Jobs</Link>
                </li>
                <li>
                  <Link to="/">Companies</Link>
                </li>
                <li>
                  <Link to="posts">Posts</Link>
                </li>
                <li>
                  <Link to="/">Articles</Link>
                </li>
                <li>
                  <Link to="/">Newsletters</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="about">About</Link>
            </li>
            <li>
              <Link to="community">Community Guidelines</Link>
            </li>
            <li>
              <Link to="legal">Legal Terms</Link>
            </li>
            <li>
              <Link to="help">Help Center</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
