import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Footer from "../../core/components/layout/footer/Footer";
import logo from "../../assets/img/np-logo.svg";
import { ToastContainer, toast } from "react-toastify";
import OtpInput from "react-otp-input";
import { useNavigate, useLocation } from "react-router-dom";
import { validateOtp, resend2faOtp } from "../../store/auth/authActions";
import "react-toastify/dist/ReactToastify.css";

export default function Otp2fa() {
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const [currentInterval, setCurrentInterval] = useState(null);
  const [countdown, setCountdown] = useState(60);

  const [resend, setResend] = useState(false);
  const otpRegEx = /\b\d{5}\b/;
  const navigate = useNavigate();
  const location = useLocation();

  const validateOtpInput = () => {
    if (otpRegEx.test(otp.toString())) {
      return true;
    } else {
      return false;
    }
  };

  const handleOTPSubmit = () => {
    if (validateOtpInput()) {
      dispatch(validateOtp(otp))
        .then((response) => {
          if (!response.is2fa) {
            if (location?.state?.navigateto === "lead_management") {
              navigate("/leads-management");
            } else {
              navigate("/dashboard", {
                state: {
                  from: location,
                },
              });
            }
          }
        })
        .catch((e) => {
          setOtpError(e.response.data.message);
        });
    } else {
      setOtpError("Please enter a valid OTP.");
    }
  };

  const handleResend = () => {
    dispatch(resend2faOtp())
      .then((res) => {
        setResend(true);
        if (res.success) {
          toast.success(res.message, { position: "bottom-left" });
        } else {
          toast.error(res.data.message, { position: "bottom-left" });
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, { position: "bottom-left" });
      });
  };

  useEffect(() => {
    if (resend) {
      setTimeout(() => {
        if (countdown == 0) {
          setCountdown(60);
          setResend(false);
        } else {
          setCountdown(countdown - 1);
        }
      }, 1000);
    }
  }, [resend, countdown]);

  return (
    <div className="login">
      {/* <Header /> */}
      <div className="banner border-5">
        <div className="container pt-5">
          <div className="row">
            <div className="col-lg-6">
              <div className="heading">
                <h1>2FA Authentication</h1>
                <p>
                  You have opted for Two-Factor Authentication (2FA) in your
                  account settings. Please enter the OTP shared to your
                  registered email here to proceed further
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <>
                <div className="forgot">
                  <form className="auth-form">
                    <h2 className="dark-black">Enter OTP</h2>
                    <div style={{ height: "60px", width: "280px" }}>
                      <OtpInput
                        value={otp}
                        onChange={(e) => {
                          setOtp(e);
                          setOtpError("");
                        }}
                        numInputs={5}
                        renderSeparator={<span>.</span>}
                        renderInput={(props) => <input {...props} />}
                        inputStyle={{
                          height: "60px",
                          width: "100%",
                          margin: "15px 0 0 0",
                        }}
                      />
                      {otpError != "" ? (
                        <p style={{ color: "red" }}>{otpError}</p>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="text-center d-grid my-2 mt-5">
                      <button
                        type="button"
                        className="btn btn-blue"
                        onClick={() => handleOTPSubmit()}
                      >
                        Login to NobelPage
                      </button>
                    </div>
                    <div className="text-center">
                      <p className="create-account">
                        Didn't receive the OTP? {/* <Link to="/signup"> */}
                        {!resend ? (
                          <span
                            className="resend-btn"
                            style={{ color: "#0432a3", fontWeight: "700" }}
                            onClick={() => handleResend()}
                          >
                            Resend OTP
                          </span>
                        ) : (
                          <span
                            className="resend-btn"
                            style={{ color: "#0432a3", fontWeight: "700" }}
                          >
                            Resend in {countdown} sec.
                          </span>
                        )}
                        {/* </Link> */}
                      </p>
                    </div>
                  </form>
                </div>
              </>
            </div>
          </div>
        </div>
        <div className="n-logo">
          <img src={logo} alt="" width="100%" />
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </div>
  );
}
