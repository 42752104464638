import React, { useEffect, useState } from "react";
import Footer from "../../core/components/layout/footer/Footer";
import Header from "../../core/components/layout/Header/Header";
import Heading from "../../core/components/layout/Heading";
import logo from "../../assets/img/np-logo.svg";
import info from "../../assets/img/info.svg";
import pass from "../../assets/img/shield-security.svg";
import "./ChangePassword.scss";
import FormInput from "../../core/components/Inputs/FormInput";
import { useLocation, useNavigate } from "react-router-dom";
import { call } from "../../api/apiCall";
import { toast } from "react-toastify";

export default function ChangePassword() {
  const [showMsg, setShowMsg] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passErrorMessage, setPassErrorMessage] = useState("");
  const [confirmPassErrorMessage, setConfirmPassErrorMessage] = useState("");
  const toggleShow = () => setShowMsg((value) => !value);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location && location.state && location.state.token) {
      console.log(location.state.token);
    } else {
      navigate("/error");
    }
  });

  const validation = () => {
    let passwordValid =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!password) {
      setPassErrorMessage("Please Enter Your Password");
      return;
    }
    if (!passwordValid.test(password)) {
      setPassErrorMessage(
        'The password should be minimum 8 characters long and contain at least one : Upper and Lower Case Alphabet, Number, Special Character. The special characters that can be used are [!""#$%&\'()*+,-./:;<=>?@[]^_`{|}~]'
      );
      return;
    }
    if (!(password === confirmPassword)) {
      setConfirmPassErrorMessage("Passwords do not match");
      return;
    }

    handleSubmit();
  };
  const handelPassChange = (e) => {
    setPassword(e.target.value);
    setPassErrorMessage("");
    setConfirmPassErrorMessage("");
  };
  const handelConfirmPassChange = (e) => {
    setConfirmPassword(e.target.value);
    setConfirmPassErrorMessage("");
  };
  const handleSubmit = async () => {
    let resetPassData = {
      newPassword: password,
    };

    try {
      const res = await call(
        "post",
        "api/v1/auth/reset-password",
        null,
        resetPassData,
        location.state.token
      );
      if (res.data.success === true) {
        // alert("Password has been changed successfully.");
        toast.success("Password has been changed successfully.");
        //  {
        //    <label className="label-input100 text-danger">
        //     You have successfully reset your password, Please Login
        //     </label>}
        navigate("/");
      }
      if (res.data.success === false) {
        // alert(res.data.message)
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <Header />
      <div className="banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              {/* <Heading />  */}
              <div class="heading">
                <h1> Your Professional Networking Platform!</h1>
                <p>
                  Connect, collaborate, and grow with professionals worldwide.
                  Explore opportunities, showcase your skills, and stay ahead in
                  your industry. Join us today and unlock your potential. Here
                  Skills win over Bias!
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="forgot">
                <form className="auth-form dark-blue-color">
                  <h2>Change Password</h2>
                  <p>
                    Enter your new password. Remember, it can't be the same as
                    your previous one.
                  </p>

                  <FormInput
                    label={
                      <>
                        New Password{" "}
                        <span>
                          <img
                            style={{ marginLeft: "6px", marginBottom: "3px" }}
                            src={info}
                            alt=""
                            onMouseOver={toggleShow}
                            onMouseLeave={toggleShow}
                          />
                        </span>
                      </>
                    }
                    icon={pass}
                    maxlength="24"
                    type="password"
                    placeholder="Enter Password"
                    error={passErrorMessage}
                    onChange={handelPassChange}
                    containerClassName={passErrorMessage ? "error-form" : ""}
                  />
                  {showMsg == true && (
                    <div className="info">
                      <h6>Pasword requirements</h6>
                      <ul className="p-0">
                        <li>
                          Must contain both uppercase and lowercase characters
                          (e.g., a-z and A-Z)
                        </li>
                        <li>Must contain at least one number (e.g., 0-9).</li>
                        <li>Must be between 6-24 character</li>
                      </ul>
                      <div className="box"></div>
                      <div className="angle"></div>
                    </div>
                  )}
                  <FormInput
                    label="Confirm Password"
                    icon={pass}
                    type="password"
                    placeholder="Retype Password"
                    maxlength="24"
                    onChange={handelConfirmPassChange}
                    error={confirmPassErrorMessage}
                    containerClassName={
                      confirmPassErrorMessage ? "error-form" : ""
                    }
                  />
                  <div
                    className="text-center d-grid"
                    style={{ marginTop: "30px" }}
                  >
                    <div className="btn btn-blue" onClick={validation}>
                      Change Password
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="minHeight"></div>
        </div>
        <div className="n-logo">
          <img src={logo} alt="" width="100%" />
        </div>
      </div>
      <Footer />
    </div>
  );
}
