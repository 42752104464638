import React, { useEffect, useMemo, useState } from "react";
import "./ChatPeople.scss";
import moment from "moment";
import { useSelector } from "react-redux";
import placeholder from "../../../../../assets/img/default_profile.png";
import groupPlaceholder from "../../../../../assets/img/default_group_icon.svg";
import { TiArrowForward } from "react-icons/ti";

const GetTime = (date) => {
  moment.updateLocale("en", {
    relativeTime: {
      future: "in %s",
      past: function (value) {
        return value;
      },
      s: "just now",
      m: "1 min",
      mm: "%d mins",
      h: "1 hr",
      hh: "%d hrs",
      d: "1 day",
      dd: "%d days",
      M: "1 month",
      MM: "%d months",
      y: "1 yr",
      yy: "%d yrs",
    },
  });
  let result = moment(date).fromNow();
  const now = moment();
  const hours = now.diff(date, "hours");
  const days = now.diff(date, "days");
  const weeks = now.diff(date, "weeks");
  if (hours >= 22 && hours <= 47) {
    result = `1 day`;
  } else if (days >= 7) {
    if (days <= 13) {
      result = `1 week`;
    } else if (days > 13 && days <= 25) {
      result = `${weeks} weeks`;
    }
  }
  return result;
};

export default function ChatPeoples({
  activeTab,
  activeBox,
  setActiveBox,
  filterType,
  setFilterType,
  data,
  handleTabChange,
  // xmppService,
  chatService,
}) {
  const { user } = useSelector((state) => state.auth);

  // Check away message
  const isAwayMessage = (message) => {
    if (
      message &&
      message?.message &&
      new Date(message?.startDate) <= new Date() &&
      new Date(message?.endDate) >= new Date()
    )
      return true;
    return false;
  };

  const lastMessageBy = (item) => {
    if (item?.lastMessages?.length > 0) {
      if (user?.data?.id === item?.lastMessages[0]?.User?.user_id) return "You";
      else return item?.lastMessages[0]?.User?.firstName?.split(" ")[0];
    } else {
      if (item?.lastMessages?.User?.user_id == user?.data?.id) return "You";
      else return item?.lastMessages?.User?.firstName?.split(" ")[0];
    }
  };

  const lastMessage = (item) => {
    if (item?.lastMessages?.length > 0) {
      return item?.lastMessages[0]?.message;
    } else {
      if (item?.lastMessages?.messageType == 7) return `Forwarded message`;
      return item?.lastMessages?.message;
    }
  };

  function isSystemMessage(message, messageTemplate) {
    console.log(message, messageTemplate, "fff");

    const replacements = {
      actionedBy: message?.lastMessages?.actionedBy
        ? message?.lastMessages?.actionedBy?.user_id == user?.data?.id
          ? `You`
          : `${message?.lastMessages?.actionedBy?.firstName} ${message?.lastMessages?.actionedBy?.lastName}`
        : "",
      actionedTo: message?.lastMessages?.actionedTo
        ? `${message?.lastMessages.actionedTo?.firstName} ${message?.lastMessages?.actionedTo?.lastName}`
        : "",
    };

    return messageTemplate?.replace(
      /\[(actionedBy|actionedTo)\]/g,
      (match, key) => replacements[key] || match
    );
  }
  return (
    <div className="chat-people mt-20">
      {filterType && (
        <div className="row mb-20">
          <div className="col-12">
            <div className="archieve">
              <span onClick={() => setFilterType(null)}>
                <span className="px-2">X </span>

                {filterType == "archive"
                  ? "Archived Messages"
                  : filterType == "request"
                  ? "Request Messages"
                  : filterType == "block"
                  ? "Blocked Messages"
                  : filterType == "spam"
                  ? "Spam Messages"
                  : filterType == "unread"
                  ? "Unread Messages"
                  : ""}
              </span>
            </div>
          </div>
        </div>
      )}
      {data?.map((item, index) => {
        const unread =
          item?.users?.find((obj) => obj?.user?.user_id === user?.data?.id)
            ?.unread || 0;
        return (
          <React.Fragment key={index}>
            <div
              className={activeBox?._id == item?._id ? "box active" : "box"}
              onClick={() => {
                setActiveBox(item);
                // handleTabChange("message");
              }}
            >
              <div className="row">
                <div className="col-lg-3 col-2 pe-0">
                  <div className="profile-img">
                    <img
                      src={
                        activeTab === "group"
                          ? item.roomLogo || groupPlaceholder
                          : item.User?.profileImg || placeholder
                      }
                      className="image-fit"
                      alt=""
                    />
                    {/* {activeTab !== "group" &&
                    xmppService?.isConnectedUser(item?.User?.jid) && (
                      <div className="active"></div>
                    )} */}
                    {activeTab !== "group" && (
                      <div
                        className={`${
                          item.User?.isOnline
                            ? "status-indicator online"
                            : isAwayMessage(item?.User?.awayMessage)
                            ? "status-indicator away"
                            : ""
                        }`}
                      ></div>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 col-7">
                  <div className="detail">
                    {console.log(item)}
                    <h5 className="card-control-text-oneline">
                      {activeTab === "group"
                        ? item?.roomName ||
                          chatService?.getRoomName(item?.users, user?.data?.id)
                        : `${item?.User?.firstName} ${item?.User?.lastName}`}
                    </h5>
                    <h6
                      className="card-control-text-twoline"
                      style={
                        unread > 0 ? { color: "#000", fontWeight: 600 } : {}
                      }
                      title={isSystemMessage(item, lastMessage(item))}
                    >
                      {isSystemMessage(item, lastMessage(item))} :{" "}
                      {item?.lastMessages?.messageType == 7 ? (
                        <TiArrowForward size={20} />
                      ) : (
                        ""
                      )}{" "}
                      {isSystemMessage(item, lastMessage(item))}
                    </h6>
                  </div>
                </div>
                <div className="col-lg-3 col-3 m-auto">
                  <div className="float-end">
                    {unread > 0 && (
                      <div className="unread text-center">
                        <span>{unread}</span>
                      </div>
                    )}
                    <div className="minutes text-center">
                      <span>{GetTime(item.updatedAt)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {index !== data?.length - 1 && <div className="line"></div>}
          </React.Fragment>
        );
      })}
      {!data?.length && (
        <div className="text-center">
          {activeTab == "message" ? "No Conversations" : "No Groups"}
        </div>
      )}
    </div>
  );
}
