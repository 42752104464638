import React, { useState, useEffect } from "react";
import "./hashTags.scss";
import Autocomplete from "react-autocomplete";
import { useDispatch } from "react-redux";
import { GetAllUsers } from "../../../../store/getAllUsers/allUsersAction";

function TagsInput(props) {
  const { tags, setTags, placeholder } = props;

  const [textInput, setTextInput] = useState("");
  const [memberList, setMemberList] = useState([]);
  const [tagsOpen, setTagsOpen] = useState(false);
  const [hastTagMessage, setHashTagMessage] = useState("");

  const dispatch = useDispatch();

  function handleKeyDown(e) {
    if (e.key !== "Enter") return;
    const value = e.target.value;
    if (!value.trim()) return;
    setHashTagMessage("");
    setTags([...tags, value]);
    // Clear the input
    e.target.value = "";
    setTextInput("");
  }
  function removeTag(index) {
    setTags(tags.filter((el, i) => i !== index));
  }

  useEffect(() => {
    if (textInput) {
      dispatch(GetAllUsers({ search: textInput.val }))
        .then((res) => {
          if (res?.success) {
            setMemberList(res?.data);
          } else setMemberList([]);
        })
        .catch(() => {
          setMemberList([]);
        });
    }
  }, [textInput]);

  return (
    <div className="new-message-list tags-input-container">
      {tags?.map((tag, index) => (
        <div className="tag-item" key={index}>
          <span className="text">{`${tag?.first_name} ${tag?.last_name}`}</span>
          <span className="close" onClick={() => removeTag(index)}>
            &times;
          </span>
        </div>
      ))}
      <div className="autocomplete-wrapper1">
        <Autocomplete
          inputProps={{ width: "90%" }}
          open={tagsOpen}
          value={textInput.val}
          items={memberList}
          getItemValue={(item) => `${item.first_name} ${item.last_name}`}
          renderMenu={(items, value) => (
            <div className="dropdown">
              {items.length === 0 && textInput !== ""
                ? `No matches for ${value}`
                : items}
            </div>
          )}
          renderItem={(item, isHighlighted) => (
            <div className={`item ${isHighlighted ? "selected-item" : ""}`}>
              {item.first_name} {item.last_name}
            </div>
          )}
          onChange={(event, val) => {
            setTextInput({ val });
            if (val.length === 0) {
              if (tagsOpen) setTagsOpen(false);
            } else {
              if (!tagsOpen) setTagsOpen(true);
            }
          }}
          onSelect={(val, obj) => {
            if (!tags.includes(obj)) setTags([...tags, obj]);
            setTextInput("");
            setTagsOpen(false);
          }}
          renderInput={(params) => {
            return (
              <input
                {...params}
                type="text"
                onKeyDown={handleKeyDown}
                className="tags-input"
                placeholder={placeholder}
              />
            );
          }}
          wrapperStyle={{}}
        />
      </div>
    </div>
  );
}

export default TagsInput;
