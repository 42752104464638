import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import DatePickerCustom from "../../../../core/components/DatePicker/DatePickerReact";
import moment from "moment";
import { getAwayMessages } from "../../../../store/message/messageActions";
import { useDispatch, useSelector } from "react-redux";
import { initLoad, stopLoad } from "../../../../store/loader/loaderActions";

const AwayMessageModal = ({
  modal,
  toggle,
  title,
  // setAwayMessageCheckModal,
  updateAwayMessage,
}) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [message, setMessage] = useState("");
  const { user } = useSelector((state) => state.auth);

  const [error, setError] = useState({
    message: "",
    start_date: "",
    end_date: "",
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initLoad());
    dispatch(getAwayMessages(user?.data?.id))
      .then((data) => {
        if (data) {
          setStartDate(data?.user?.awayMessage?.start_date);
          setEndDate(data?.user?.awayMessage?.end_date);
          setMessage(data?.user?.awayMessage?.message);
        }
        dispatch(stopLoad());
      })
      .catch((err) => dispatch(stopLoad()));
  }, []);

  const checkValidation = (type = "all") => {
    let formIsValid = true;

    if (type === "message" || type === "all") {
      if (!message?.trim()) {
        setError((p) => ({ ...p, message: "Message is mandatory." }));
        formIsValid = false;
        // return;
      } else {
        setError({ ...error, message: "" });
      }
    }

    if (type === "start_date" || type === "all") {
      if (!startDate) {
        setError((p) => ({ ...p, start_date: "Start date is mandatory" }));
        formIsValid = false;
        // return;
      } else {
        setError({ ...error, start_date: "" });
      }
    }

    if (type === "end_date" || type === "all") {
      if (!endDate) {
        setError((p) => ({ ...p, end_date: "End date is mandatory" }));
        formIsValid = false;
        // return;
      } else {
        setError({ ...error, end_date: "" });
      }
    }
    return formIsValid;
  };

  const handleSubmit = () => {
    if (checkValidation()) {
      if (updateAwayMessage)
        updateAwayMessage(user?.data?.id, {
          start_date: new Date(startDate),
          end_date: new Date(endDate),
          message,
        });
      toggle();
    }
  };

  return (
    <>
      <Modal
        size="xl"
        isOpen={modal}
        className={"modal-size-sm popup-message-modal gradient-background"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        zIndex={0}
      >
        {
          <ModalHeader className="p-3" toggle={toggle}>
            <h3
              className="d-flex gap-3 align-items-center"
              style={{ color: "rgb(255, 255, 255)", fontSize: "15px" }}
            >
              {title}
            </h3>
          </ModalHeader>
        }
        <ModalBody>
          <div className="set-away-messages-popup">
            <div className="set-away-messages-popup-outer">
              {/* <div className="heading mb-10">
                <h5>Set away messages</h5>
              </div> */}
              <div className="row mb-20">
                <div className="col-lg-12">
                  <p>
                    This message informs others that you are temporarily
                    unavailable and unable to respond immediately.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 mb-20">
                  <label className="form-label">Start Date</label>
                  <div className="react-datepicker-wrapper">
                    <div className="react-datepicker__input-container">
                      {/* <input
                        placeholder="--select date--"
                        className="example-custom-input react-datepicker-ignore-onclickoutside form-control"
                        value=""
                      /> */}
                      <DatePickerCustom
                        placeholder="--select date--"
                        value={startDate ? new Date(startDate) : ""}
                        onChange={(date) => {
                          setStartDate(
                            date ? moment(date).format("YYYY-MM-DD") : ""
                          );
                        }}
                        min={new Date()}
                        onBlur={() => checkValidation("start_date")}
                      />
                      {error?.start_date && (
                        <p className="text-danger">{error?.start_date}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mb-20">
                  <label className="form-label">End Date</label>
                  <div className="react-datepicker-wrapper">
                    <div className="react-datepicker__input-container">
                      {/* <input
                        placeholder="--select date--"
                        className="example-custom-input react-datepicker-ignore-onclickoutside form-control"
                        value=""
                      /> */}
                      <DatePickerCustom
                        placeholder="--select date--"
                        value={endDate ? new Date(endDate) : ""}
                        onChange={(date) => {
                          setEndDate(
                            date ? moment(date).format("YYYY-MM-DD") : ""
                          );
                        }}
                        min={moment(startDate).add(1, "day").toDate()}
                        onBlur={() => checkValidation("end_date")}
                      />
                      {error?.end_date && (
                        <p className="text-danger">{error?.end_date}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <label
                    for="exampleFormControlTextarea1"
                    className="form-label"
                  >
                    Message
                  </label>
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    maxLength={100}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onBlur={() => checkValidation("message")}
                    placeholder="--type message--"
                  />
                  {error?.message && (
                    <p className="text-danger">{error?.message}</p>
                  )}
                </div>
                <div className="text-center">
                  <button
                    className="btn btn-blue mt-5"
                    onClick={() => handleSubmit()}
                  >
                    Set Message
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
export default AwayMessageModal;
