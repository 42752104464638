import {
  CREATE_CONVERSATION,
  CREATE_GROUP_CONVERSATION,
  GET_CONVERSATION,
  GET_GROUP_CONVERSATION,
  REMOVE_CONVERSATION,
  REMOVE_GROUP_CONVERSATION,
  UPDATE_CONVERSATION,
  UPDATE_GROUP_CONVERSATION,
  UPDATE_UNREAD,
  UPDATE_GROUP_MEMBER,
  REMOVE_GROUP_MEMBER,
} from "../types";
import store from "../store";
export const initialState = {
  conversations: [],
  // groupConversations: [],
};

const message = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_CONVERSATION:
      return {
        ...state,
        conversations: payload?.data?.rooms || [],
      };

    case CREATE_CONVERSATION:
      return {
        ...state,
        conversations: [
          payload,
          ...state.conversations?.filter((c) => c?._id !== payload?._id),
        ],
      };

    case UPDATE_CONVERSATION:
      const { newMsg, loginUser } = payload;
      console.log(newMsg, loginUser);

      const conversation = state?.conversations?.find(
        (c) => c?.code === newMsg?.roomCode
      );

      return {
        ...state,
        conversations: [
          {
            ...conversation,
            users: conversation?.users?.map((ele) =>
              ele?.user?.user_id == loginUser ? { ...ele, unread: 0 } : ele
            ),
            lastMessages: conversation?.lastMessages?.length
              ? conversation?.lastMessages
              : { ...newMsg, User: newMsg?.user },
          },
          ...state.conversations?.filter((c) => c?.code !== newMsg?.roomCode),
        ],
      };
    case REMOVE_CONVERSATION:
      return {
        ...state,
        conversations: [
          ...state.conversations?.filter((c) => c?.id !== payload?.id),
        ],
      };

    case UPDATE_UNREAD:
      return {
        ...state,
        conversations: state.conversations?.map((c) =>
          c?.code === payload?.roomCode
            ? {
                ...c,
                users: c?.users?.map((obj) =>
                  obj?.user?.user_id == payload?.user_id
                    ? { ...obj, unread: payload?.data || 0 }
                    : obj
                ),
              }
            : c
        ),
      };

    case GET_GROUP_CONVERSATION:
      return {
        ...state,
        conversations: payload?.data?.rooms || [],
      };

    case CREATE_GROUP_CONVERSATION:
      return {
        ...state,
        conversations: [
          payload,
          ...state.conversations?.filter((c) => c?.id !== payload?.id),
        ],
      };

    case UPDATE_GROUP_CONVERSATION:
      return {
        ...state,
        conversations: [
          payload,
          ...state.conversations?.filter((c) => c?.id !== payload?.id),
        ],
      };
    case REMOVE_GROUP_CONVERSATION:
      return {
        ...state,
        conversations: [
          ...state.conversations?.filter((c) => c?.id !== payload?.id),
        ],
      };
    case UPDATE_GROUP_MEMBER:
      console.log(
        state?.conversations?.some(
          (ele) => ele?.code !== payload?.updatedRoom?.code
        )
      );

      return {
        ...state,
        conversations: state?.conversations?.some(
          (ele) => ele?.code == payload?.updatedRoom?.code
        )
          ? state.conversations?.map((c) =>
              c?.code === payload?.updatedRoom?.code
                ? {
                    ...payload?.updatedRoom,
                  }
                : c
            )
          : [payload.updatedRoom, ...state.conversations],
      };
    case REMOVE_GROUP_MEMBER:
      const { roomCode, user_id } = payload;
      console.log(payload, state.conversations);

      return {
        ...state,
        conversations: state.conversations.map((c) =>
          c?.code === roomCode
            ? {
                ...c,
                users: c.users.filter((u) => u.user?._id != user_id),
              }
            : c
        ),
      };

    default:
      return state;
  }
};

export default message;
