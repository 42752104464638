import React, { useEffect, useState } from "react";
import Footer from "../../core/components/layout/footer/Footer";
import Header from "../../core/components/layout/Header/Header";
import Heading from "../../core/components/layout/Heading";
import logo from "../../assets/img/np-logo.svg";
import { useLocation, useNavigate } from "react-router-dom";
import "./Otp.scss";
import FormInput from "../../core/components/Inputs/FormInput";
import { call } from "../../api/apiCall";
import { useDispatch } from "react-redux";
import { verify_email } from "../../store/profile/profileActions";
import { ToastContainer, toast } from "react-toastify";

export default function Otp(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState("");
  const [showResentError, setShowResentError] = useState("");
  const [showMessage, setShowMessage] = useState(true);
  // const [loading, setLoading] = useState(false)
  const { isSignup } = location?.state;
  useEffect(() => {
    if (
      (location && location?.state && location?.state?.email) ||
      (location && location?.state && location?.state?.phone)
    ) {
      console.log(location?.state?.email);
    } else {
      navigate("/error");
    }
  });
  const validation = () => {
    if (!otp?.trim()) {
      setErrorMessage("Please Enter Your OTP");
    } else {
      handleSubmit();
    }
  };
  let email = location?.state?.email;
  let phone = location?.state?.phone;
  let forgotPassData = {
    email: email,
  };
  let forgotPassPhoneData = {
    phone: phone,
  };
  const handelResent = async () => {
    let data;
    if (location?.state?.email) {
      data = forgotPassData;
    } else {
      data = forgotPassPhoneData;
    }
    try {
      const res = await call("post", "api/v1/auth/forgot-password", null, data);
      if (res.data.success == true) {
        setShowResentError(res.data.message);
      }
      // else {
      //   setShowError(res.data.message)
      // // console.log("forgot unsuccess data", res.data);
      // //  {res.data.message && <label className="label-input100 text-danger">{res.data.message}</label>}
      // // return
      // }
    } catch (err) {
      console.log(err);
      setShowError(err.response.data.message);
    }
  };
  const handleSubmit = async () => {
    let otpEmailData = {
      email: email,
      otp: otp,
    };
    let otpPhoneData = {
      phone: phone,
      otp: otp,
    };

    let otpData;
    if (location.state.email) {
      otpData = otpEmailData;
    } else {
      otpData = otpPhoneData;
    }

    try {
      const res = await call("post", "api/v1/auth/verify-otp", null, otpData);
      if (res.data.success === true) {
        // console.log("otp res", res.data);
        setShowResentError(res.data.message);
        if (isSignup) {
          const verifyRes = dispatch(verify_email());
          console.log("verifyRes", verifyRes);
          setTimeout(() => {
            navigate("/intro");
          }, 1500);
          if (verifyRes.sucess === true) {
            toast.success("You have succesfully verified your email id", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          }
        } else
          navigate("/change-password", { state: { token: res.data.token } });
      }
    } catch (err) {
      console.log(err);
      setShowError(err.response.data.message);
    }
  };
  const handelChangeOtp = (e) => {
    setOtp(e.target.value);
    setErrorMessage("");
    setShowError("");
    setShowResentError("");
  };
  useEffect(() => {
    setTimeout(() => {
      setShowMessage(false);
    }, 2000);
  }, []);

  return (
    <div>
      <Header />
      <div className="banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <Heading />
              <h1 style={{ fontSize: "48px", color: "white" }}>
                {" "}
                Your Professional Networking Platform!
              </h1>
              <p style={{ fontSize: "16px", color: "white" }}>
                Connect, collaborate, and grow with professionals worldwide.
                Explore opportunities, showcase your skills, and stay ahead in
                your industry. Join us today and unlock your potential. Here
                Skills win over Bias!
              </p>
            </div>
            <div className="col-lg-6">
              <div className="forgot ">
                <form className="auth-form">
                  <h2 className="dark-blue-color">Enter OTP</h2>
                  {showError && (
                    <label className="label-input100 text-danger">
                      {showError}
                    </label>
                  )}
                  {showResentError && (
                    <label className="label-input100 text-primary">
                      {showResentError}
                    </label>
                  )}
                  {showMessage && (
                    <label className="label-input100 text-primary">
                      OTP has been sent successfully to registered email Id.
                    </label>
                  )}
                  <FormInput
                    type="text"
                    label="OTP"
                    maxlength="4"
                    onChange={handelChangeOtp}
                    placeholder="Enter One Time Password"
                    error={errorMessage}
                    style={{ padding: ".375rem .75rem" }}
                    containerClassName={errorMessage ? "error-form" : ""}
                  />

                  <p className="mt-3 ">
                    Check your registered email and enter the sent OTP.
                  </p>

                  <p className="otp-span" onClick={handelResent}>
                    {" "}
                    Resend OTP
                  </p>

                  <div className="text-center d-grid mt-4">
                    <div className="btn btn-blue" onClick={validation}>
                      Verify
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="minHeight"></div>
        </div>
        <div className="n-logo">
          <img src={logo} alt="" width="100%" />
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </div>
  );
}
