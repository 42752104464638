import Footer from "../../core/components/layout/footer/Footer";
import Header from "../../core/components/layout/Header/Header";
import Heading from "../../core/components/layout/Heading";
import email from "../../assets/img/a.svg";
import profile from "../../assets/img/profile-circle.svg";
import pass from "../../assets/img/shield-security.svg";
import logo from "../../assets/img/np-logo.svg";
import { Link } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import "./Signup.scss";
import FormInput from "../../core/components/Inputs/FormInput";
import SocialLoginButton from "../../core/components/SocialLoginButton/SocialLoginButton";
import MobileInput from "../../core/components/Inputs/MobileInput";
import { ToastContainer } from "react-toastify";

export default function Signup(props) {
  const {
    setFirstName,
    checkSignUpValidation,
    errorFirstName,
    setLastName,
    errorLastName,
    setEmailName,
    errorEmail,
    setPassword,
    errorPassword,
    setPhoneNumber,
    phoneNumber,
    errorPhoneNumber,
    submitSignUpData,
    errorMessage,
    setPhoneCount,
    handleEnterSubmit,
    data,
  } = props;

  return (
    <div>
      <Header />
      <div className="banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <Heading data={data} />
            </div>
            <div className="col-lg-6">
              <div className="signup">
                <form className="auth-form">
                  <h2 className="auth-form-heading dark-black">Sign up</h2>
                  {/* <div className="d-flex justify-content-center flex-wrap gap-2">
                    <SocialLoginButton type="Google" source="signup" />
                    <SocialLoginButton type="Facebook" source="signup" />
                    <SocialLoginButton type="Linkedin" source="signup" />
                  </div> */}
                  {/* <div className="d-flex align-items-center mt-2 ">
                    <hr className="w-100" />
                    <span className="text-nowrap mx-1">or use your email</span>
                    <hr className="w-100" />
                  </div> */}
                  <div className="row">
                    <FormInput
                      label="First Name"
                      icon={profile}
                      type="text"
                      onChange={(e) => setFirstName(e.target.value)}
                      onBlur={() => checkSignUpValidation("firstName")}
                      placeholder="--type--"
                      error={errorFirstName}
                      onKeyDown={(e) => handleEnterSubmit(e)}
                      containerClassName={
                        "col-lg-6 " + (errorFirstName ? "error-form" : "")
                      }
                    />
                    <FormInput
                      label="Last Name"
                      icon={profile}
                      type="text"
                      onChange={(e) => setLastName(e.target.value)}
                      onBlur={() => checkSignUpValidation("lastName")}
                      placeholder="--type--"
                      error={errorLastName}
                      onKeyDown={(e) => handleEnterSubmit(e)}
                      containerClassName={
                        "col-lg-6 " + (errorLastName ? "error-form" : "")
                      }
                    />
                  </div>
                  <FormInput
                    label="Email"
                    icon={email}
                    type="email"
                    onChange={(e) => setEmailName(e.target.value)}
                    placeholder="--type--"
                    onBlur={() => checkSignUpValidation("emailName")}
                    error={errorEmail}
                    onKeyDown={(e) => handleEnterSubmit(e)}
                    containerClassName={errorEmail ? "error-form" : ""}
                  />
                  <FormInput
                    label="Password"
                    icon={pass}
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                    onBlur={() => checkSignUpValidation("password")}
                    placeholder="--type--"
                    onKeyDown={(e) => handleEnterSubmit(e)}
                    error={errorPassword}
                    containerClassName={errorPassword ? "error-form" : ""}
                  />
                  <MobileInput
                    label="Mobile"
                    onChange={(value, country, e, formattedValue) => {
                      setPhoneNumber(
                        `+${country.dialCode}-${value.slice(
                          country.dialCode.length
                        )}`
                      );
                      setPhoneCount(country.countryCode);
                    }}
                    value={phoneNumber}
                    error={errorPhoneNumber}
                    onBlur={() => checkSignUpValidation("phoneNumber")}
                    placeholder="--type--"
                    containerClassName={
                      "pt-3 " + (errorPhoneNumber ? "error-form" : "")
                    }
                    onKeyDown={(e) => handleEnterSubmit(e)}
                  />
                  <div className="text-center">
                    <p>
                      By clicking &quot;Join Community&quot;, you agree to
                      NobelPage&apos;s{" "}
                      <Link to="/legal">
                        <span>Legal Terms</span>
                      </Link>{" "}
                      including Terms of Use, Privacy Policy, Cookie Policy and
                      Community GuidelinesTerms and Conditions & Privacy Policy
                    </p>
                  </div>
                  <div className="text-center d-grid mt-3 mt-lg-4">
                    <button
                      type="button"
                      className="btn btn-blue"
                      onMouseDown={submitSignUpData}
                    >
                      Join Community
                    </button>
                  </div>
                  {errorMessage && (
                    <label className="label-input100 text-danger">
                      {errorMessage}
                    </label>
                  )}
                  <div className="text-center login-info">
                    <p>
                      Already have an account?{" "}
                      <Link to="/">
                        <span className="font-700">Login</span>
                      </Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="n-logo">
          <img src={logo} alt="" width="100%" />
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </div>
  );
}
