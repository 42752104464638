import React, { useState } from "react";
import email from "../assets/img/a.svg";
import pass from "../assets/img/shield-security.svg";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../store/auth/authActions";
import FormInput from "../core/components/Inputs/FormInput";
import SocialLoginButton from "../core/components/SocialLoginButton/SocialLoginButton";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { loadUser } from "../store/auth/authActions";
import { getTokenFirebase } from "../routes/Notifications/NotifyFirebase";

export default function Login() {
  const [emailName, setEmailName] = useState("");
  const [password, setPassword] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const checkValidation = (type) => {
    let emailvalid =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let passwordValid =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    let formIsValid = true;

    if (type === "emailName" || type === "all") {
      if (!emailName) {
        setErrorEmail("Please enter your email.");
        formIsValid = false;
      } else if (!emailvalid.test(emailName)) {
        setErrorEmail("Please enter your email in a valid format.");
        formIsValid = false;
      } else {
        setErrorEmail("");
      }
    }
    if (type === "password" || type === "all") {
      console.log("check password", password);
      if (!password) {
        setErrorPassword("Please enter your password.");
        formIsValid = false;
      } else if (!passwordValid.test(password)) {
        setErrorPassword(
          'The password should be minimum 8 characters long and contain at least one : Upper and Lower Case Alphabet, Number, Special Character. The special characters that can be used are [!""#$%&\'()*+,-./:;<=>?@[]^_`{|}~]'
        );
        formIsValid = false;
      } else {
        setErrorPassword("");
      }
    }
    console.log("formis valid", formIsValid);
    return formIsValid;
  };

  const handleFormSubmit = async () => {
    let registration_type = "email";
    let token;
    let fcm_token = localStorage.getItem("firebase_token");
    if (!fcm_token) {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        token = await getTokenFirebase();
      }
    }
    dispatch(
      login(
        emailName,
        password,
        registration_type,
        rememberMe,
        fcm_token || token
      )
    )
      .then((response) => {
        if (response.is2fa) {
          navigate("/2fa-verification");
        } else {
          if (location?.state?.navigateto === "lead_management") {
            navigate("/leads-management");
          }
          if (location?.state?.navigateto === "jobs") {
            navigate("/jobs");
          }
          if (location?.state?.navigateto === "interview-preperation") {
            navigate("/interview-preperation");
          }
          if (location?.state?.navigateto === "careers") {
            navigate("/explore");
          } else {
            const redirectTo = location?.state?.navigateto || "/dashboard";
            dispatch(loadUser(false));
            navigate(redirectTo, { replace: true });
          }
        }
      })
      .catch((e) => {
        let message =
          (e.response && e.response.data && e.response.data.message) ||
          e.message ||
          e.toString();
        setErrorMessage(message);
      });
  };

  const handleEnterSubmit = (e) => {
    if (e.key === "Enter") {
      submitLoginData(e);
    }
  };

  const submitLoginData = (e) => {
    e.preventDefault();
    setErrorMessage("");
    console.log(checkValidation("all"), "Chec Validatiaoall");
    if (checkValidation("all")) {
      handleFormSubmit();
    }
  };

  const { isLoggedIn } = useSelector((state) => state.auth);

  // if (isLoggedIn) {
  // return location?.state?.from?.pathname==="/" ? <Navigate to="/dashboard" />: <Navigate to={location?.state?.from?.pathname} state={{ from: location }} />
  // }
  if (isLoggedIn) {
    try {
      if (location?.state?.navigateto === "lead_management") {
        navigate("/leads-management");
      } else
        navigate("/dashboard", {
          state: { commingFromLogin: true },
        });
    } catch (error) {
      console.log("error", error);
    }
  }
  if (isLoggedIn) {
    try {
      if (location?.state?.navigateto === "jobs") {
        navigate("/jobs");
      } else
        navigate("/dashboard", {
          state: { commingFromLogin: true },
        });
    } catch (error) {
      console.log("error", error);
    }
  }

  if (isLoggedIn) {
    try {
      if (location?.state?.navigateto === "interview-preperation") {
        navigate("/interview-preperation");
      } else
        navigate("/dashboard", {
          state: { commingFromLogin: true },
        });
    } catch (error) {
      console.log("error", error);
    }
  }
  if (isLoggedIn) {
    try {
      if (location?.state?.navigateto === "careers") {
        navigate("/explore");
      } else
        navigate("/dashboard", {
          state: { commingFromLogin: true },
        });
    } catch (error) {
      console.log("error", error);
    }
  }

  return (
    <>
      <div className="login">
        <form className="auth-form">
          <h2
            style={{ marginBottom: "24px", paddingBottom: "0px" }}
            className="dark-black"
          >
            Log in
          </h2>
          {/* <div className="d-flex justify-content-center flex-wrap gap-2">
        <SocialLoginButton type="Google" source="login" />
        <SocialLoginButton type="Facebook" source="login" />
        <SocialLoginButton type="Linkedin" source="login" />
      </div>
      <div className="d-flex align-items-center mt-2 ">
        <hr className="w-100" />
        <span className="text-nowrap mx-1">or use your email</span>
        <hr className="w-100" />
      </div> */}
          {errorMessage && (
            <label className="label-input100 text-danger">{errorMessage}</label>
          )}
          <FormInput
            style={{ marginBottom: "14px" }}
            label="Email"
            icon={email}
            type="email"
            onChange={(e) => setEmailName(e.target.value)}
            placeholder="Enter Email"
            onBlur={() => checkValidation("emailName")}
            error={errorEmail}
            onKeyDown={(e) => handleEnterSubmit(e)}
            containerClassName={errorEmail ? "error-form" : ""}
          />
          <FormInput
            style={{ marginBottom: "14px" }}
            label="Password"
            icon={pass}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter Password"
            onBlur={() => checkValidation("password")}
            error={errorPassword}
            onKeyDown={(e) => handleEnterSubmit(e)}
            containerClassName={errorPassword ? "error-form" : ""}
          />
          <div className="d-flex checkbox">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
                onClick={() => setRememberMe(!rememberMe)}
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                Keep me logged in
              </label>
            </div>
            <Link to="/forgot">
              <span className="forgot">Forgot your password?</span>
            </Link>
          </div>
          <div
            className="text-center d-grid my-2"
            style={{ marginBottom: "20px !important" }}
          >
            <button
              type="button"
              className="btn btn-blue"
              onMouseDown={(e) => submitLoginData(e)}
            >
              Login to NobelPage
            </button>
          </div>
          <div className="text-center" marginTop="20px">
            <p className="create-account">
              Don’t have an account?{" "}
              <Link to="/signup">
                <span style={{ color: "#0432a3", fontWeight: "700" }}>
                  Create Account
                </span>
              </Link>
            </p>
          </div>
        </form>
      </div>
    </>
  );
}
