import React, { useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Label,
  FormGroup,
  ModalFooter,
} from "reactstrap";
import { get_Report_type } from "../../../store/dashboard/dashboardActions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { submit_report } from "../../../store/dashboard/dashboardActions";
import { useState } from "react";
import DeleteModal from "../../../core/components/modal/DeleteModal";
import { useNavigate } from "react-router-dom";
import { GetTime } from "../../../core/components/Utility/Utility";
import TagsInput from "../../../pages/message/component/tagsInput/tagsInput";
import { AddMember, RemoveMember } from "../../../store/message/messageActions";
import deleteIcon from "../../../assets/img/chatDelete.svg";
import SocketService from "../../../pages/message/service/socket/socket.service";
import { UPDATE_GROUP_MEMBER } from "../../../store/types";
export default function AddMemberModel(props) {
  const chatService = SocketService;
  const { user } = useSelector((state) => state.auth);
  const { modal, toggle, data, setData } = props;
  const [roomData, setRoomData] = useState();
  const [newMembers, setNewMembers] = useState([]);
  const [roomName, setRoomName] = useState(data?.roomName);
  const { conversations } = useSelector((state) => state.chat);
  const dispatch = useDispatch();
  const handleSubmit = () => {
    dispatch(
      AddMember({
        user: newMembers?.map((member) => ({ user: member?.jid })),
        roomCode: data?.code,
        roomName,
      })
    )
      .then((res) => {
        setData(res?.data?.updatedRoom);
        toggle();
      })
      .catch((err) => {
        console.log(err?.response?.data?.error);
        toggle();
      });
  };

  console.log(conversations);
  useEffect(() => {
    const room = conversations?.find((room) => room?.code === data?.code);
    console.log(room);
    if (room) {
      setRoomData(room);
    }
  }, []);

  const handleRemoveMember = (id) => {
    dispatch(
      RemoveMember({
        user_id: id,
        roomCode: data?.code,
        loginUser: user?.data?.jid,
      })
    )
      .then((res) => {
        console.log(res);
        // toggle();
      })
      .catch((err) => {
        console.log(err?.response?.data?.message);
      });
  };

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className="add-section report-modal fix-width gradient-background"
        centered
      >
        <ModalHeader style={{ padding: "20px !important" }} toggle={toggle}>
          Manage Group
        </ModalHeader>
        <ModalBody>
          <div className="mt-2">
            <p style={{ fontSize: "12px" }} className="text-secondary">
              {
                conversations?.find((room) => room?.code === data?.code)?.users
                  ?.length
              }{" "}
              Members .Last activity {GetTime(data?.updatedAt)}
            </p>
            <div>
              <Input
                type="input"
                value={roomName}
                id="exampleSelect"
                onChange={(e) => setRoomName(e.target.value)}
                placeholder="Enter room name"
              ></Input>
            </div>
            <div className="d-flex mt-3 w-100 border p-1">
              <TagsInput
                tags={newMembers}
                setTags={setNewMembers}
                placeholder={"Search for name to add in group"}
              />
            </div>

            <div className="mt-3">
              <h6>Members</h6>
              <div
                style={{ height: "200px", overflow: "auto", padding: "10px" }}
              >
                {conversations
                  ?.find((room) => room?.code === data?.code)
                  ?.users?.map((item, index) => (
                    <div
                      key={index}
                      className="d-flex align-items-center justify-content-between mt-4 border-bottom"
                    >
                      <div className="d-flex align-items-center pb-2">
                        <img
                          src={item?.user?.profileImg}
                          alt=""
                          className="rounded-circle"
                          style={{ width: "40px", height: "40px" }}
                        />
                        <strong className="mb-0 ms-2">
                          {item?.user?.firstName} {item?.user?.lastName}
                        </strong>
                      </div>
                      {user?.data?.id !== item?.user?.user_id && (
                        <div>
                          <img
                            src={deleteIcon}
                            onClick={() => handleRemoveMember(item?.user?._id)}
                          />
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
            <ModalFooter className="d-flex justify-content-center mt-3">
              <button
                onClick={() => handleSubmit()}
                className={"blue noBorder whiteText"}
              >
                Add
              </button>
            </ModalFooter>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}
